export const fadeOutElement = (element, options = {}) => {
  const { duration = 600, easing = "ease-in" } = options;
  return new Promise((resolve) => {
    // Set pre-transition state
    element.style.setProperty("display", "block");
    element.style.setProperty("visibility", "visible");
    element.style.setProperty("opacity", 1);
    // Trigger a reflow so that we can then use a transition
    element.offsetWidth; // eslint-disable-line no-unused-expressions

    // Clean up after the transition ends
    const handler = () => {
      element.removeEventListener("transitionend", handler);
      element.style.setProperty("display", "none");
      element.style.setProperty("visibility", "hidden");
      resolve();
    };
    element.addEventListener("transitionend", handler);

    // Set the end state styles
    element.style.setProperty("transition", `opacity ${duration}ms ${easing}`);
    element.style.setProperty("opacity", 0);
  });
};

export const fadeOutElements = (elements, options = {}) => {
  const promises = [...elements].map((element) => {
    return fadeOutElement(element, options);
  });
  return Promise.all(promises);
};

export const fadeInElement = (element, options = {}) => {
  const { duration = 600, easing = "ease-in" } = options;
  return new Promise((resolve) => {
    // Set pre-transition state
    element.style.setProperty("display", "block");
    element.style.setProperty("visibility", "visible");
    element.style.setProperty("opacity", 0);

    // Trigger a reflow so that we can then use a transition
    element.offsetWidth; // eslint-disable-line no-unused-expressions

    // Clean up after the transition ends
    const handler = () => {
      element.removeEventListener("transitionend", handler);
      resolve();
    };
    element.addEventListener("transitionend", handler);

    // Set the end state styles
    element.style.setProperty("transition", `opacity ${duration}ms ${easing}`);
    element.style.setProperty("opacity", 1);
  });
};

export const fadeInElements = (elements, options = {}) => {
  const promises = [...elements].map((element) => {
    return fadeInElement(element, options);
  });
  return Promise.all(promises);
};

export const slideInElement = (element, options = {}) => {
  const { duration = 600, easing = "ease-in-out", translate = "0px" } = options;
  return new Promise((resolve) => {
    // Trigger a reflow so that we can then use a transition
    element.offsetWidth; // eslint-disable-line no-unused-expressions
    element.style.setProperty(
      "transition",
      `transform ${duration}ms ${easing}`
    );
    element.style.setProperty("transform", `translateY(${translate})`);
    resolve();
  });
};

export const slideOutElement = (element, options = {}) => {
  const {
    duration = 600,
    easing = "ease-in-out",
    translate = "-100%",
  } = options;
  return new Promise((resolve) => {
    element.style.setProperty(
      "transition",
      `transform ${duration}ms ${easing}`
    );
    element.style.setProperty("transform", `translateY(${translate})`);
    resolve();
  });
};
