/* JavaScript for AudioModule */
import app from "../ps1_app";
import { AudioPlayer } from "../components/audio_player";
import { isNumberAndNotNaN } from "../utilities";

export class AudioModule {
  constructor(element) {
    this.element = element;
    this.playPauseButton = this.element.querySelector(
      ".js-audio-module-play-pause-button"
    );
    this.playhead = this.element.querySelector(".js-audio-module-playhead");
    this.scrubber = this.element.querySelector(".js-audio-module-scrubber");
    this.progressBar = this.element.querySelector(
      ".js-audio-module-progress-bar"
    );
    this.timeRemainingLabel = this.element.querySelector(
      ".js-audio-module-time-remaining"
    );
    this.currentTrackTitle = this.element.querySelector(
      ".js-audio-module-track-title"
    );

    this.currentTrackImage = this.element.querySelector(
      ".js-audio-module-track-image"
    );

    this.scrubBack = this.element.querySelector(".js-audio-module__scrub-back");
    this.scrubForward = this.element.querySelector(
      ".js-audio-module__scrub-forward"
    );

    this.skipPlayerButton = this.element.querySelector(".js-skip-player");

    this.returnToPlayerButton = this.element.querySelector(
      ".js-return-to-player"
    );

    this.audios = this.element.querySelectorAll(".js-audio-module-player");
    this.metaContainer = this.element.querySelector(".js-meta-container");
    this.metaContent = this.element.querySelector(".js-meta-content");
    this.tracklistButtons = this.element.querySelectorAll(
      ".js-meta-tracklist__track"
    );
    this.tracklistControl = this.element.querySelector(
      ".js-meta-tracklist-control"
    );
    this.trackImages = this.element.querySelectorAll(".js-track-image");
    this.transcriptControl = this.element.querySelector(
      ".js-meta-transcript-control"
    );
    this.tracklistContent = this.element.querySelector(".js-meta-tracklist");
    this.transcriptContent = this.element.querySelector(".js-meta-transcript");
    this.transcripts = this.element.querySelectorAll(".js-track-transcript");
    this.prevControl = this.element.querySelector(".js-meta-prev-control");
    this.nextControl = this.element.querySelector(".js-meta-next-control");
    this.tracklistData = [];

    // State
    this.state = {
      currentTrack: 0,
      audio: this.audios[0],
      paused: true,
      progress: 0,
      duration: null,
      timeRemaining: null,
      trackEnded: null,
      progressBarWidth: null,
      progressBarOffset: null,
      isScrubbing: false,
      tracklistOpen: false,
      transcriptOpen: false,
    };

    // Events
    this.events = {};

    this.player = this.state.audio;

    // Initiate separate states for each audio track
    this.tracks = this.audios.forEach((audio) => {
      this.tracklistData.push({ ...audio.dataset });
      new AudioPlayer(audio, this);
    });

    // Classes
    this.classes = {
      PAUSED: "audio-module--paused",
      PLAYING: "audio-module--playing",
      CURRENT: "--current",
      HIDDEN: "--hidden",
      IMAGE: "--image",
      DISABLED: "disabled",
      OPEN: "--open",
    };

    // sets progress bar dimensions once css has had a chance to load
    setTimeout(() => {
      this.state.progressBarWidth = this.progressBar.offsetWidth;
      this.state.progressBarOffset = this.progressBar.offsetLeft;
    }, 500);

    // Initial render
    this._render(this.state);

    // clicking play pause toggles paused state
    this.playPauseHandler = this.togglePlayPause.bind(this);
    this.playPauseButton.addEventListener("click", this.playPauseHandler);

    // Pressing down on the pointer within the scrubber will start listening for scrub events
    this.pointerDownHandler = this._handleScrubStart.bind(this);
    this.scrubber.addEventListener("mousedown", this.pointerDownHandler);
    this.scrubber.addEventListener("touchstart", this.pointerDownHandler);

    // Simply releasing the mouse anywhere (like a click) will seek the audio, if the  mousedown started within the scrubber
    this.pointerUpHandler = this._handleScrubEnd.bind(this);
    document.addEventListener("mouseup", this.pointerUpHandler);
    document.addEventListener("touchend", this.pointerUpHandler);

    // Moving the pointer will scrub through the  audio
    this.pointerMoveHandler = this._handleScrubSeek.bind(this);
    document.addEventListener("mousemove", this.pointerMoveHandler);
    document.addEventListener("touchmove", this.pointerMoveHandler);

    // Navigating elsewhere destroys the instance
    $(document).one("turbo:before-cache", function () {
      this.destroy();
    });

    // Other components should be able to pause all present audio modules via AudioModule.pauseAll()
    this.globalPauseHandler = this.pause.bind(this);
    $(document).on("app:audio-module-pause", this.globalPauseHandler);

    // Set event listeners if audio player is meta
    if (this.audios.length > 1) {
      this.tracklistControl.addEventListener("click", () => {
        const currentState = this.state.tracklistOpen;
        this._update({
          tracklistOpen: !currentState,
          transcriptOpen: false,
        });
      });

      // Cycle through tracklist
      this.prevControl.addEventListener("click", () => {
        this._handleTrackChange("prev");
      });

      // Cycle through tracklist
      this.nextControl.addEventListener("click", () => {
        this._handleTrackChange();
      });

      // Collect playback information from audio player if track button is clicked
      this.tracklistButtons.forEach((button) => {
        button.addEventListener("click", () => {
          this.pause();
          this._update({
            currentTrack: Number(button.dataset.trackId),
          });
          this.play();
        });
      });
    }

    // Toggle transcript window
    if (this.transcriptControl) {
      this.transcriptControl.addEventListener("click", () => {
        const currentState = this.state.transcriptOpen;
        this._update({
          tracklistOpen: false,
          transcriptOpen: !currentState,
        });
      });
    }

    // Allow users using keyboard controls to skip element
    this.skipPlayerButton.addEventListener("click", () => {
      this.returnToPlayerButton.focus();
    });

    // Allow users using keyboard to skip back to start of element
    this.returnToPlayerButton.addEventListener("click", () => {
      this.playPauseButton.focus();
    });
  }

  /*
  ::::::::::::::::::::::::
  :: CORE FUNCTIONALITY ::
  ::::::::::::::::::::::::
  */

  _update(update) {
    const previousState = Object.assign({}, this.state);
    this.state = Object.assign(this.state, update);
    return this._render(update, previousState);
  }

  _render(update, previousState) {
    if (update.hasOwnProperty("duration")) {
      this._renderDuration();
    }

    if (update.hasOwnProperty("paused")) {
      this._renderPlayPause();
    }

    if (update.hasOwnProperty("progress")) {
      this._setPlayheadPosition();
      this._renderTimeRemaining();
    }

    if (update.hasOwnProperty("trackEnded")) {
      this._handleTrackEnded();
    }

    if (update.hasOwnProperty("currentTrack")) {
      this._renderCurrentTrack(previousState?.currentTrack);
      this._renderCurrentTitle();
      this._renderCurrentImage();
      this._renderCurrentTrackTranscript();
      this._renderPrevNexControl();
    }

    if (update.hasOwnProperty("tracklistOpen")) {
      this._rendermeta();
      this._renderTranscript();
    }
  }

  /*
  :::::::::::::::::::
  :: STATE HELPERS ::
  :::::::::::::::::::
  */

  _recordDuration() {
    let duration = secondsToReadableTime(this.player.duration);
    this._update({ duration: duration });
  }

  _getTimeRemaining() {
    let timeRemaining = secondsToReadableTime(
      this.player.duration - this.player.currentTime
    );
    // timeRemaining = "-" + timeRemaining;
    // return wrapInParens(timeRemaining);
    return timeRemaining;
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubStart() {
    if (!this.player.readyState === 0) {
      return;
    }

    this._update({ isScrubbing: true });
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubEnd(e) {
    if (this.state.isScrubbing) {
      let progress =
        (e.pageX - this.state.progressBarOffset) / this.state.progressBarWidth;
      progress = clamp(progress, 0, 1);
      let seekedTime = Math.round(progress * this.player.duration);
      this.player.currentTime = seekedTime;
    }
    this._update({ isScrubbing: false });
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubSeek(e) {
    if (!this.state.isScrubbing || !this.player.readyState === 0) {
      return;
    }

    let progress =
      (e.pageX - this.state.progressBarOffset) / this.state.progressBarWidth;
    progress = clamp(progress, 0, 1);
    let seekedTime = Math.round(progress * this.player.duration);
    this.player.currentTime = seekedTime;
  }

  // Trigger auto play
  _handleTrackEnded() {
    if (!this.state.trackEnded) {
      return;
    }
    this._handleTrackChange();
  }

  // Cycle forward or back through tracklist where applicable
  _handleTrackChange(seek = "next") {
    const seekNext = seek === "next";
    if (this.audios.length <= 1) {
      return;
    }
    // Seek next if not at end of tracklist
    if (seekNext && this.audios.length != this.state.currentTrack + 1) {
      this._changeTrack(this.state.currentTrack + 1);
    }
    // Seek prev if not at first of tracklist
    else if (!seekNext && this.audios.length != 0) {
      this._changeTrack(this.state.currentTrack - 1);
    }
  }

  // Update the current track
  _changeTrack(newTrack) {
    this.player.pause();
    this._update({
      currentTrack: newTrack,
    });
    this.play();
  }

  /*
  ::::::::::::::::::::
  :: RENDER HELPERS ::
  ::::::::::::::::::::
  */

  _renderPlayPause() {
    if (this.state.paused) {
      this.element.classList.remove(this.classes.PLAYING);
      this.element.classList.add(this.classes.PAUSED);
    } else {
      this.element.classList.remove(this.classes.PAUSED);
      this.element.classList.add(this.classes.PLAYING);
    }
  }

  _renderDuration() {
    if (!this.state.duration) {
      return;
    }
    this.timeRemainingLabel.innerText = this.state.duration;
  }

  _setPlayheadPosition() {
    let translation =
      "translateX(calc((" +
      this.state.progressBarWidth +
      "px - 100%) * " +
      this.state.progress +
      "))";
    this.playhead.style.transform = translation;
  }

  _renderTimeRemaining() {
    if (!this.state.timeRemaining) {
      return;
    }

    this.timeRemainingLabel.innerText = `-${this.state.timeRemaining}`;
  }

  _renderCurrentTrack(previousTrack) {
    this.player = this.audios[this.state.currentTrack];
    const trackChangedFromOneToAnother = previousTrack !== undefined;
    if (trackChangedFromOneToAnother) {
      this.playPauseButton.focus();
    }
  }

  _renderCurrentTitle() {
    if (!this.tracklistData[this.state.currentTrack].title) {
      return;
    }
    const newTitle = this.tracklistData[this.state.currentTrack].title;
    this.currentTrackTitle.innerHTML = `${newTitle}`;
  }

  _renderCurrentImage() {
    const hasImage =
      this.tracklistData[this.state.currentTrack].image === "true";
    if (!hasImage) {
      this.playPauseButton.classList.remove(
        `audio-module-play-pause-button${this.classes.IMAGE}`
      );
    } else {
      this.trackImages.forEach((image) => {
        if (this.state.currentTrack === Number(image.dataset.trackId)) {
          this.playPauseButton.classList.add(
            `audio-module-play-pause-button${this.classes.IMAGE}`
          );
          this.currentTrackImage.src = image.src;
        }
      });
    }
  }

  _rendermeta() {
    if (this.audios.length <= 1) {
      return;
    }
    if (this.state.tracklistOpen) {
      this.tracklistControl.classList.add(`meta-control${this.classes.OPEN}`);
      this.tracklistContent.classList.remove(
        `meta-tracklist${this.classes.HIDDEN}`
      );
    } else {
      this.tracklistControl.classList.remove(
        `meta-control${this.classes.OPEN}`
      );
      this.tracklistContent.classList.add(
        `meta-tracklist${this.classes.HIDDEN}`
      );
    }
  }

  _renderTranscript() {
    const hasTranscript =
      this.tracklistData[this.state.currentTrack].transcript === "true";
    if (!hasTranscript) {
      return;
    }
    if (this.state.transcriptOpen) {
      this.transcriptContent.classList.remove(
        `meta-transcript${this.classes.HIDDEN}`
      );
      this.transcriptControl.classList.add(`meta-control${this.classes.OPEN}`);
    } else {
      this.transcriptContent.classList.add(
        `meta-transcript${this.classes.HIDDEN}`
      );
      this.transcriptControl.classList.remove(
        `meta-control${this.classes.OPEN}`
      );
    }
  }

  _renderCurrentTrackTranscript() {
    const hasTranscript =
      this.tracklistData[this.state.currentTrack].transcript === "true";
    if (hasTranscript) {
      this.transcriptControl.classList.remove(
        `meta-control${this.classes.HIDDEN}`
      );
      if (this.state.transcriptOpen) {
        this.transcriptContent.classList.remove(
          `meta-transcript${this.classes.HIDDEN}`
        );
      }
      // if transcript data-track-id matches current track: reveal
      // otherwise: hide
      this.transcripts.forEach((transcript) => {
        if (this.state.currentTrack === Number(transcript.dataset.trackId)) {
          transcript.classList.remove(`track-transcript${this.classes.HIDDEN}`);
        } else {
          transcript.classList.add(`track-transcript${this.classes.HIDDEN}`);
        }
      });
    } else {
      if (this.transcriptControl) {
        this.transcriptControl.classList.add(
          `meta-control${this.classes.HIDDEN}`
        );
        this.transcriptContent.classList.add(
          `meta-transcript${this.classes.HIDDEN}`
        );
        if (this.audios.length <= 1) {
          this.metaContent.classList.add(`meta-content${this.classes.HIDDEN}`);
        }
      }
    }
  }

  _renderPrevNexControl() {
    if (this.audios.length <= 1) {
      return;
    }
    if (this.state.currentTrack === this.audios.length - 1) {
      this.nextControl.setAttribute("disabled", "");
      this.prevControl.removeAttribute("disabled");
    } else if (this.state.currentTrack === 0) {
      this.prevControl.setAttribute("disabled", "");
      this.nextControl.removeAttribute("disabled");
    } else {
      this.nextControl.removeAttribute("disabled");
      this.prevControl.removeAttribute("disabled");
    }
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  play() {
    this.pauseAll();
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  destroy() {
    this.pause();
    this.playPauseButton.removeEventListener("click", this.playPauseHandler);
    this.scrubber.removeEventListener("mousedown", this.pointerDownHandler);
    this.scrubber.removeEventListener("touchstart", this.pointerDownHandler);
    document.removeEventListener("mouseup", this.pointerUpHandler);
    document.removeEventListener("touchend", this.pointerUpHandler);
    document.removeEventListener("mousemove", this.pointerMoveHandler);
    document.removeEventListener("touchmove", this.pointerMoveHandler);
    $(document).off("app:audio-module-pause", this.globalPauseHandler);
  }

  pauseAll() {
    $(document).trigger("app:audio-module-pause");
  }

  roundTo(input, numberOfDecimals) {
    return (
      Math.round(input * Math.pow(10, numberOfDecimals)) /
      Math.pow(10, numberOfDecimals)
    );
  }
}

/*
:::::::::::::::
:: UTILITIES ::
:::::::::::::::
*/

function secondsToReadableTime(seconds) {
  var minutes = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return [minutes, seconds].join(":");
}

function wrapInParens(str) {
  return "(" + str + ")";
}

function roundTo(input, numberOfDecimals) {
  return (
    Math.round(input * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals)
  );
}

function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

export const audioModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the AudioModule on any given page
  app.addEventListener("pageLoad", () => {
    audioModules.current = [
      ...document.querySelectorAll(".js-audio-module"),
    ].map((instance) => new AudioModule(instance));
  });
};
