export const init = () => {
  document.addEventListener("click", (e) => {
    const button = e.target.closest(".js-async-button-link");
    if (!button) {
      return;
    }

    const { url } = button.dataset;
    if (!url) {
      return;
    }
    e.preventDefault();
    const method = button.dataset.method || "get";
    fetch(url, { method }).then((response) => {
      if (response.ok) {
        if (button.dataset.reloadOnSuccess) {
          window.location.reload();
        } else if (button.dataset.redirectOnSuccess) {
          window.location.href = button.dataset.OnSuccess;
        } else if (button.dataset.alertOnSuccess) {
          alert(button.dataset.alertOnSuccess);
        }
      } else {
        if (button.dataset.alertOnError) {
          alert(button.dataset.alertOnError);
        }
      }
    });
  });
};
