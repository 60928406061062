/* JavaScript for CookiePopup */
import app from "../ps1_app";
import * as util from "../utilities";

// Export the class itself
export class CookiePopup {
  constructor(element) {
    this.element = element;
    this.agreeButton = this.element.querySelector(
      ".js-cookie-popup__action-agree"
    );
    this.disagreeButton = this.element.querySelector(
      ".js-cookie-popup__action-disagree"
    );
    this.popupHeight = this.element.offsetHeight;

    // OTHERWISE, WAIT FOR USER INPUT
    this.agreeButton.addEventListener("click", () => {
      this.consent();
    });
    this.disagreeButton.addEventListener("click", () => {
      this.declineConsent();
    });
  }

  declineConsent() {
    this.element.style.transform = `translateY(${this.popupHeight}px)`;
    util.setCookie("gdpr_consent", "false", 365);
  }

  consent() {
    this.element.style.transform = `translateY(${this.popupHeight}px)`;
    util.setCookie("gdpr_consent", "true", 365);
  }
}

// Exports an array of all the current instances
export const cookiePopup = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    cookiePopup.current = [
      ...e.target.querySelectorAll(".js-cookie-popup"),
    ].map((instance) => new CookiePopup(instance));
  });
};
