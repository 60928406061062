import app from "../ps1_app";
import * as util from "../utilities";
import { GenericCarousel } from "./generic_carousel";
import focusLock from "./focus-lock";

const OPEN_CLASS = "lightbox--open";
const ROOT_OPEN_CLASS = "lightbox-is-open";
const PREVIOUS_NEXT_CONTROLS_VISIBLE_CLASS = "lightbox--previous-nextable";

export class Lightbox extends GenericCarousel {
  /* Public methods */
  open(content, index = 0) {
    // Force input content to be an Array
    let slides;
    if (NodeList.prototype.isPrototypeOf(content)) {
      slides = [...content];
    } else {
      slides = [].concat(content);
    }

    // Force each slide to be saved as a string
    // Filter out anything we don't know what to do with
    slides = slides.reduce((acc, slide) => {
      if (typeof slide === "string") {
        acc.push(slide);
      } else if (slide.nodeType && slide.nodeType === 1) {
        acc.push(slide.outerHTML);
      } else if (slide.nodeType && slide.nodeType === 11) {
        acc.concat(slide.children.map((c) => c.outerHTML));
      }
      return acc;
    }, []);

    this.update({
      slides,
      index,
      content: slides[index],
      maxIndex: slides.length - 1,
      open: true,
      focus: this.firstFocusableElement,
      focusReleaseTarget: document.activeElement,
      focusLock: true,
    });

    // Update the prev/next controls so that they reflect the current state
    this.updateControls();

    this.trigger("open");
  }

  close() {
    const update = {};
    update.open = false;
    update.content = "";
    update.focus = this.state.focusReleaseTarget;
    update.focusReleaseTarget = null;
    update.focusLock = false;
    update.color = "color-default";
    update.canGoPrevious = false;
    update.canGoNext = false;
    this.update(update);

    this.trigger("close");
  }

  updateContent(index = this.state.index) {
    this.update({
      content: this.state.slides[index],
    });
  }

  previous() {
    super.previous();
    this.updateContent();
  }

  next() {
    super.next();
    this.updateContent();
  }

  select() {
    super.next();
    this.updateContent();
  }

  /* Initialzer methods */
  setElements() {
    this.element = document.getElementById("lightbox");
    this.content = document.getElementById("lightbox-content");
    this.closeButton = document.getElementById("lightbox-close");
    this.previousNextControls = this.element.querySelector(
      ".js-previous-next-controls"
    );
    super.setElements(this.element);
  }

  setUpEvents() {
    super.setUpEvents();

    app.addEventListener("click", {
      name: "lightbox-closer",
      handler: (e) => {
        if (!e.target.closest(".js-lightbox-close")) {
          return;
        }
        this.close();
      },
    });

    // Clicking outside the lightbox closes it
    app.addEventListener("click", {
      name: "lightbox-backdrop-click-closer",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        if (
          this.element.contains(e.target) &&
          !this.content.contains(e.target) &&
          !e.target.matches(".js-lightbox-controls *")
        ) {
          this.close();
        }
      },
    });

    app.addEventListener("keydown", {
      name: "lightbox-keys-events",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        switch (e.keyCode) {
          // Left arrow
          case 37:
            this.previous();
            break;
          // Right arrow
          case 39:
            this.next();
            break;
          // Escape key
          case 27:
            this.close();
            break;
          default:
        }
      },
    });

    // Elements can encode lightbox content as a data attribute.
    // When those elements are clicked, the data attribute's content will be used for the lightbox.
    app.addEventListener("click", {
      name: "lightbox-opener",
      handler: (e) => {
        const element = e.target.closest("[data-lightbox]");
        if (!element) {
          return;
        }

        const markup = element.getAttribute("data-lightbox");
        this.open(markup);
      },
    });
  }

  /* State management methods */
  get defaultState() {
    return Object.assign({}, parent.defaultState, {
      open: false,
      content: null,
      focus: null,
      focusReleaseTarget: null,
      focusLock: false,
      previousNextControls: false,
      slides: [],
      wrapAround: true,
      maxIndex: null,
    });
  }

  get firstFocusableElement() {
    // If the prev/next controls are hidden, use the close button
    if (this.state.canGoPrevious) {
      return this.previousControl;
    }
    if (this.state.canGoNext) {
      return this.nextControl;
    }
    return this.closeButton;
  }

  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    this.render(update, previousState);
    return previousState;
  }

  /* Rendering methods */
  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("content")) {
      this.renderLightboxBody(update.content);
    }

    if (update.hasOwnProperty("open")) {
      this.renderOpenState(update.open);
    }

    if (update.focus) {
      update.focus.focus();
    }

    if (update.hasOwnProperty("focusLock")) {
      if (this.state.focusLock) {
        this.unlockFocus = focusLock.lock({
          selectors: [".js-lightbox *"],
          loopTarget: () => {
            return this.firstFocusableElement;
          },
        });
      } else if (this.unlockFocus) {
        this.unlockFocus();
      }
    }

    if (
      update.hasOwnProperty("canGoPrevious") &&
      update.hasOwnProperty("canGoNext")
    ) {
      this.renderPreviousNextControls();
    }
  }

  renderOpenState(isOpen) {
    const classListMethod = isOpen ? "add" : "remove";
    this.element.classList[classListMethod](OPEN_CLASS);
    document.documentElement.classList[classListMethod](ROOT_OPEN_CLASS);
  }

  renderLightboxBody(html) {
    this.content.innerHTML = html;
  }

  renderPreviousNextControls() {
    if (!this.state.canGoNext && !this.state.canGoPrevious) {
      this.element.classList.remove(PREVIOUS_NEXT_CONTROLS_VISIBLE_CLASS);
    } else {
      this.element.classList.add(PREVIOUS_NEXT_CONTROLS_VISIBLE_CLASS);
    }
  }

  /*
    Misc
  */
  trigger(event, args = {}) {
    super.trigger(event, args);

    app.trigger(`lightbox:${event}`, args);
  }
}

export const lightbox = {
  current: null,
};

export const init = () => {
  document.addEventListener("DOMContentLoaded", () => {
    if (util.isEconomyEditMode()) {
      return;
    }
    lightbox.current = new Lightbox();
  });

  app.addEventListener("turbolinks:load", () => {
    if (!lightbox.current) {
      return;
    }

    lightbox.current.setElements();
    lightbox.current.setUpEvents();
  });
};

window.lightbox = lightbox;
