/* JavaScript for ShareActions */
import * as animations from "../../utilities/animations";

// Export the class itself
export class ShareActions {
  constructor(element) {
    this.element = element;
    this.copyButton = this.element.querySelector(".js-tray-share__copy-button");
    this.toolTip = this.element.querySelector(".js-tray-share__tooltip");
    this.printButton = this.element.querySelector(
      ".js-tray-share__print-button"
    );
    this.input = this.element.querySelector(".js-tray-share__input");
    this.currentPath = window.location.href;
    this.input.value = this.currentPath;

    this.copyButton.addEventListener("click", () => {
      this.copy();
    });

    this.copyButton.addEventListener("keydown", (e) => {
      if (e.key === 13) {
        this.copy();
      }
    });

    this.printButton.addEventListener("click", () => {
      window.print();
    });

    this.printButton.addEventListener("keydown", (e) => {
      if (e.key === 13) {
        window.print();
      }
    });
  }

  copy() {
    navigator.clipboard.writeText(this.currentPath);
    this.toolTip.innerHTML = "Copied!";
    animations.fadeInElement(this.toolTip, {
      duration: 150,
    });
  }

  reset() {
    this.toolTip.removeAttribute("style");
    this.toolTip.innerHTML = "";
  }
}
