/* JavaScript for CommunityInterruptionModule */
import app from "../ps1_app";
import { isEconomyEditMode, interpolate } from "../utilities";

const MODULE_SELECTOR = ".js-community-interruption-module";

// Exports an array of all the current instances
export const communityInterruptionModules = {
  current: [],
};

// Export the class itself
export class CommunityInterruptionModule {
  constructor(magicModule) {
    // Define our vars
    this.magicModule = magicModule;
    this.element = magicModule.querySelector(".js-community-interruption");
    this.sentinel = magicModule.querySelector(
      ".js-community-interruption-sentinel"
    );
    this.nextSibling = magicModule.nextElementSibling;
    this.prevSibling = magicModule.previousElementSibling;

    // Stateful properties
    this.startingTranslation = null;
    this.startingNextSiblingTranslation = null;
    this.snapIntoPlaceDelay = null;

    // This component's scroll behavior relies on the ability to sandwich itself
    // between its adjacent siblings. If it has no siblings, we cannot proceed.
    if (
      !this.prevSibling ||
      !this.nextSibling ||
      this.nextSibling.offsetHeight < this.element.offsetHeight / 2
    ) {
      return;
    }

    // To enable isomorphic debugging mode
    // add in community-interruption_module.rb:
    // def css_class
    //   "#{super} js-community-interruption__debugger"
    // end
    if (
      this.element.classList.contains("js-community-interruption__debugger")
    ) {
      this.debug();
    }

    // Set translation value on interruption and nextSibling,
    // by passing intersection ratio to interpolate
    const handleIntersect = ([sentinel]) => {
      clearTimeout(this.snapIntoPlaceDelay);

      const { intersectionRatio } = sentinel;

      // We don't want to animate the element as our sentinel enters/exits the view
      // through the top edge. If
      // NOTE: this is sort of an approximation. We assume that if this function
      // is running, then something is happening around the top or bottom edge.
      // This check tells us which side specifically.
      const distanceFromViewportTopEdge = sentinel.boundingClientRect.top;
      const isPassingThroughViewportTopEdge =
        distanceFromViewportTopEdge < app.state.windowHeight / 2;
      if (isPassingThroughViewportTopEdge) {
        return;
      }

      // Update the `transform` properties
      this.update(intersectionRatio);

      // The animation has a tendency to get stuck just slightly unfinished.
      // If at the end of this handler, the animation is indeed slightly unfishined,
      // wait for another scroll event. If none comes, snap the element into place.
      if (intersectionRatio > 0.1 && intersectionRatio < 0.9) {
        return;
      }
      this.snapIntoPlaceDelay = window.setTimeout(() => {
        this.update(Math.round(intersectionRatio));
      }, 100);
    };
    // Sets threshold to include 100 steps in animation
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: Array.from({ length: 100 }).map((_, index) => index / 100),
    };
    this.observer = new IntersectionObserver(handleIntersect, options);
    this.observer.observe(this.sentinel);

    // ::::::::::::::::::::
    // :: INITIAL RENDER ::
    // ::::::::::::::::::::
    // Without this delay, elementHeight is calculated incorrectly
    setTimeout(() => {
      this.stageIntersect();
    }, 100);

    // ::::::::::::
    // :: EVENTS ::
    // ::::::::::::

    // Reset the animation's initial state and general configuration on resize
    this.unlistenResize = app.addEventListener("resize", () => {
      this.stageIntersect();
    });

    // Destroy the instance when the user changes pages
    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );
  }

  // Apply initial translations and mount intersection observer
  stageIntersect() {
    const elementHeight = this.element.offsetHeight;
    this.startingTranslation = (-1 * elementHeight) / 2;
    this.startingNextSiblingTranslation = -1 * elementHeight;
    this.element.style.transform = `translateY(${this.startingTranslation}px)`;
    this.element.style.zIndex = "-1";
    this.nextSibling.style.zIndex = "1";
    this.nextSibling.style.transform = `translateY(${this.startingNextSiblingTranslation}px)`;
  }

  // Remove any manipulations we have done
  unstageIntersect() {
    this.element.style.removeProperty("transform");
    this.element.style.removeProperty("z-index");
    this.nextSibling.style.removeProperty("transform");
    this.nextSibling.style.removeProperty("z-index");
  }

  update(progress) {
    const elementTranslation = interpolate(
      this.startingTranslation,
      0,
      progress
    );
    const nextSiblingTranslation = interpolate(
      this.startingNextSiblingTranslation,
      0,
      progress
    );
    this.element.style.transform = `translateY(${elementTranslation}px)`;
    this.nextSibling.style.transform = `translateY(${nextSiblingTranslation}px)`;

    // Toggles z-index to 1 or -1 if animation progress is complete
    if (progress === 1) {
      this.element.style.zIndex = "1";
    } else {
      this.element.style.zIndex = "-1";
    }
  }

  debug() {
    const magicModules = document.querySelector(".magic-modules");
    magicModules.style.transform = "scale(0.5) skew(0deg, 5deg)";
    this.element.style.left = "-50px";
    this.prevSibling.style.background = "pink";
    this.nextSibling.style.background = "tomato";
  }

  destroy() {
    this.unstageIntersect();
    this.unlistenResize();
    this.unlistenDestroy();
    communityInterruptionModules.current.filter((c) => c !== this);
  }
}

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the CommunityInterruptionModule on any given page
  app.addEventListener("pageLoad", (e) => {
    if (
      isEconomyEditMode() ||
      !document.body.classList.contains("controller--homepage")
    ) {
      return;
    }

    [...e.target.querySelectorAll(MODULE_SELECTOR)].forEach((element) => {
      const instance = new CommunityInterruptionModule(element);
      if (instance) {
        communityInterruptionModules.current.push(instance);
      }
    });
  });

  app.addEventListener("homepage:load", (e) => {
    if (!e.target.matches(MODULE_SELECTOR)) {
      return;
    }

    const instance = new CommunityInterruptionModule(e.target);
    if (instance) {
      communityInterruptionModules.current.push(instance);
    }
  });
};
