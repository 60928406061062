import app from "../ps1_app";
import * as util from "../utilities";

class Homepage {
  constructor() {
    // Pull data from the DOM
    const settings = document.getElementById("homepage-settings");
    const { endpoint, sequence, page } = settings.dataset;
    // settings.parentElement.removeChild(settings);
    this.target = document.getElementById("homepage-target");
    this.loadMoreTrigger = document.getElementById(
      "homepage-load-more-trigger"
    );
    this.loadMoreButton = document.getElementById("homepage-load-more-button");
    this.endpoint = endpoint;

    // State
    this.state = {};
    this.state.sequence = sequence;
    this.state.page = parseInt(page, 10);

    // Events
    // When the user scrolls and approaches the bottom of the document, load more content
    this.scrollObserver = new IntersectionObserver(
      ([currentScrollState]) => {
        if (!currentScrollState.isIntersecting) {
          return;
        }
        this.loadMore();
      },
      {
        threshold: [0, 1],
      }
    );
    this.scrollObserver.observe(this.loadMoreTrigger);
    this.scrollObserver.observe(this.loadMoreButton);

    // If the scroll event somehow doesn't fire, we end the page with a "load more" button
    this.loadMoreButton.addEventListener("click", () => {
      this.loadMore();
    });

    // Clean up everything when the use clicks away
    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );

    // Clean up URL param if present
    if (window.location.search.includes("collapsed-header")) {
      const cleanURL = util.editSearchParams(window.location.href, (params) => {
        params.delete("collapsed-header");
      });
      history.replaceState({}, null, cleanURL);
    }
  }

  // PUBLIC METHODS
  getPage(page) {
    const url = util.editSearchParams(this.endpoint, (params) => {
      params.set("page", page);
      params.set("sequence", this.state.sequence);
    });

    return util.fetchPageMarkup(url).then((markup) => {
      // If the server did not respond with any Posts, then we have exhausted the entire table.
      // Move along to the append function so that we can use what's left in the store.
      if (!markup) {
        return Promise.reject(
          new Error("Could not fetch another page of content...")
        );
      }

      const fragment = document
        .createRange()
        .createContextualFragment(`${markup}`);

      return Promise.resolve(fragment);
    });
  }

  append(items) {
    const fragment = document.createDocumentFragment();
    items.forEach((item, index) => {
      item.style.setProperty("opacity", 0);
      item.style.setProperty(
        "transition",
        `opacity 300ms linear ${index * 200}ms`
      );
      const transitionEndHandler = () => {
        item.style.removeProperty("opacity");
        item.style.removeProperty("transition");
        item.removeEventListener("transitionend", transitionEndHandler);
      };
      item.addEventListener("transitionend", transitionEndHandler);
      fragment.appendChild(item);
    });
    this.target.appendChild(fragment);
    setTimeout(() => {
      items.forEach((item) => {
        item.style.setProperty("opacity", 1);
        app.trigger("homepage:load", { target: item });
      });
    }, 10);
  }

  loadMore() {
    if (this.state.loadInProgress) {
      return Promise.resolve();
    }

    this.update({
      page: this.state.page + 1,
      loadInProgress: true,
    });
    return this.getPage(this.state.page)
      .then((fragment) => {
        // If the server detects that we have run through the entire pre-set sequence,
        // it will give us a new sequence. We should therefore reset the current page to 1.
        const settingsUpdate = fragment.querySelector("#homepage-settings");
        if (settingsUpdate) {
          const { sequence, page } = settingsUpdate.dataset;
          this.update({
            sequence,
            page: parseInt(page, 10),
          });
          fragment.removeChild(settingsUpdate);
        }

        const items = [...fragment.children];

        return Promise.resolve(items);
      })
      .then((items) => {
        return this.append(items);
      })
      .catch((err) => {
        console.log("Something went wrong when fetching homepage content", err);
      })
      .finally(() => {
        this.update({
          loadInProgress: false,
        });
      });
  }

  // State management
  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    return previousState;
  }

  // Cleanup
  destroy() {
    this.scrollObserver.disconnect();
    this.unlistenDestroy();
  }
}

export const homepage = {
  current: null,
};

export const init = () => {
  app.addEventListener("turbolinks:load", () => {
    if (
      util.isEconomyEditMode() ||
      !document.body.classList.contains("controller--homepage")
    ) {
      return;
    }

    homepage.current = new Homepage();
  });
};
