import focusLock from "./focus-lock";
import app from "../ps1_app";
import * as util from "../utilities";

const OPEN_CLASS = "modal--open";
const ROOT_OPEN_CLASS = "modal-is-open";

export class Modal {
  constructor() {
    // State
    this.state = Object.assign({}, this.defaultState);
    this.modalHeader = document.querySelector(".page-header--post-page");
    // Events
    this.setUpEvents();
  }

  /* Public methods */
  open(content = "", location = false) {
    const update = {
      content,
      open: true,
      focus: this.firstFocusableElement,
      focusReleaseTarget: document.activeElement,
      focusLock: true,
    };

    // If a location is supplied, we update the current URL and add an entry to history.
    // If the modal is currently closed, we want to keep a record of the current page,
    // so that when the user clicks the close button, we can return here
    if (location) {
      update.location = location;
      if (!this.state.open) {
        update.pageToRetunTo = window.location.href;
      }
    }
    this.update(update);
    if (this.modalHeader) {
      app.setContentDimensions(this.modalHeader);
    }
    this.trigger("open");
  }

  close(returnToPreviousPage = true) {
    const update = {};
    update.open = false;
    update.content = "";
    update.focus = this.state.focusReleaseTarget;
    update.focusReleaseTarget = null;
    update.focusLock = false;
    update.color = "color-default";

    // If we are just closing the modal, we want to return to the previous page
    // If we're closing the modal because we are visiting a new page, we don't want to do that.
    if (this.state.pageToRetunTo && returnToPreviousPage) {
      update.location = this.state.pageToRetunTo;
    }

    update.pageToRetunTo = null;
    this.update(update);

    this.trigger("close");
  }

  load(url) {
    const formattedUrl = util.editSearchParams(url, (params) => {
      params.set("remote", "modal");
    });
    const pageToRetunTo = window.location.href;

    util.fetchPageMarkup(formattedUrl).then((html) => {
      this.open(html, url);
      this.trigger("load", {
        pageToRetunTo,
        location: url,
        target: this.modal,
      });
    });
  }

  /* Initialzer methods */
  setElements() {
    this.modal = document.getElementById("modal");
    this.modalContent = document.getElementById("modal-content");
  }

  setUpEvents() {
    app.addEventListener("click", {
      name: "modal-closer",
      handler: (e) => {
        if (!e.target.closest(".js-modal-close")) {
          return;
        }
        this.close();
      },
    });

    app.addEventListener("click", {
      name: "modal-opener",
      handler: (e) => {
        if (util.isEconomyEditMode()) {
          return;
        }

        const link = e.target.closest("[data-modal-link]");
        if (!link) {
          return;
        }

        e.preventDefault();
        this.load(link.getAttribute("data-modal-link"));
      },
    });

    app.addEventListener("popstate", {
      name: "modal-history",
      handler: (e) => {
        // If the user click "back" or "forward", and we've marked that history entry as a page that was opened in a modal,
        // then we should open the modal like
        if (e.state && e.state.isModalPage === true) {
          this.update({
            color: e.state.color,
          });
          this.open(e.state.modalContent, false);
          this.trigger("load", {
            target: this.modal,
          });
        }
        // If the "back" event is to a page that *wasn't* opened in a modal, but the modal is open,
        // then we should close the modal!
        else if (this.state.open) {
          this.close(false);
        }
      },
    });

    // Escape key closes the modal
    app.addEventListener("keydown", {
      name: "modal-escape-closer",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        if (e.keyCode === 27) {
          this.close();
        }
      },
    });

    // Clicking outside the modal closes it
    app.addEventListener("click", {
      name: "modal-backdrop-click-closer",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        if (
          this.modal.contains(e.target) &&
          !this.modalContent.contains(e.target) &&
          !e.target.matches(".js-modal-controls *")
        ) {
          this.close();
        }
      },
    });

    // Some things should open the modal with a color
    // TODO: this is pretty sloppy... Clean this up.
    app.addEventListener("click", {
      name: "modal-color-changer",
      handler: (e) => {
        const element = e.target.closest("[data-modal-color]");
        if (!element) {
          return;
        }

        const color = element.dataset.modalColor;
        this.update({ color });
      },
    });

    /* 
      If a link is clicked inside a modal page, the modal should close
      and the resultant page is loaded as a normal page.

      Using `before-cache` is important here because how we create History
      entries while working around Turbolinks. Basically, when we open the
      modal, we add a History entry with the modal content stored as in its
      snapshot state. We do that so that we can handle `popstate` events and
      restore the modal's content. Turbolinks, however, still thinks we're on
      the same page. Therefore, when Turbolinks caches the page, we want the
      captured HTML to *not* have the modal, since the page didn't have the
      modal when it was first loaded.
    */
    app.addEventListener("turbolinks:before-cache", () => {
      if (!this.state.open) {
        return;
      }

      this.close(false);
    });
  }

  /* State management methods */
  get defaultState() {
    return {
      open: false,
      content: null,
      focus: null,
      focusReleaseTarget: null,
      focusLock: false,
      location: null,
      color: "color-default",
    };
  }

  get firstFocusableElement() {
    return document.getElementById("modal-close");
  }

  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    this.render(update, previousState);
    return previousState;
  }

  /* Rendering methods */
  render(update, previousState) {
    if (update.hasOwnProperty("content")) {
      this.renderModalBody(update.content);
    }

    if (update.hasOwnProperty("open")) {
      this.renderOpenState(update.open);
    }

    if (update.hasOwnProperty("location")) {
      this.renderLocation(update.location);
    }

    if (update.hasOwnProperty("focusLock")) {
      if (this.state.focusLock) {
        this.unlockFocus = focusLock.lock({
          selectors: [".js-modal *", ".js-page-header *"],
          loopTarget: this.firstFocusableElement,
        });
      } else if (this.unlockFocus) {
        this.unlockFocus();
      }
    }

    if (update.hasOwnProperty("color")) {
      this.renderColor(previousState);
    }
  }

  renderOpenState(isOpen) {
    const classListMethod = isOpen ? "add" : "remove";
    this.modal.classList[classListMethod](OPEN_CLASS);
    document.documentElement.classList[classListMethod](ROOT_OPEN_CLASS);
  }

  renderModalBody(html) {
    this.modalContent.innerHTML = html;
    this.modalContent.scrollTo(0, 0);
  }

  renderLocation(href) {
    const snapshot = {};
    snapshot.isModalPage = Boolean(this.state.content);
    snapshot.modalContent = this.state.content;
    snapshot.pageToRetunTo = this.state.pageToRetunTo;
    snapshot.color = this.state.color;
    history.pushState(snapshot, null, href);
  }

  renderColor(previousState) {
    if (previousState.color) {
      this.modal.classList.remove(previousState.color);
    }

    if (this.state.color) {
      this.modal.classList.add(this.state.color);
    } else {
      this.modal.classList.add("color-default");
    }
  }

  /*
    Misc
  */
  trigger(event, args = {}) {
    app.trigger(`modal:${event}`, args);
  }
}

export const modal = {
  current: null,
};

export const init = () => {
  document.addEventListener("DOMContentLoaded", () => {
    if (util.isEconomyEditMode()) {
      return;
    }
    modal.current = new Modal();
  });

  app.addEventListener("turbolinks:load", () => {
    if (!modal.current) {
      return;
    }

    modal.current.setElements();
  });
};

window.modal = modal;
