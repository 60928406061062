/* JavaScript for TextAndImageModule */
import app from "../ps1_app";
import { lightbox } from "../components/lightbox";

// Export the class itself
export class TextAndImageModule {
  constructor(element) {
    this.element = element;
    this.image = element.querySelector(".js-text-and-image__button");
    const lightboxSlides = element.querySelectorAll(".js-lightbox-slide");

    // Initial render
    this.setLayoutVars();

    // Events
    this.image.addEventListener("click", () => {
      lightbox.current.open(lightboxSlides);
    });
    this.unlistenResize = app.addEventListener("resize", () => {
      this.setLayoutVars();
    });
    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );
  }

  setLayoutVars() {
    this.element.style.setProperty(
      "--text-and-image-image-width",
      `${this.image.offsetWidth}px`
    );
  }

  destroy() {
    this.unlistenResize();
    this.unlistenDestroy();
  }
}

// Exports an array of all the current instances
export const textAndImageModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the TextAndImageModule on any given page
  app.addEventListener("pageLoad", (e) => {
    textAndImageModules.current = [
      ...e.target.querySelectorAll(".js-text-and-image-module"),
    ].map((instance) => new TextAndImageModule(instance));
  });
};
