/* JavaScript for GifController */
import app, { EVENT_REDUCED_MOTION_SETTING } from "../ps1_app";
import * as util from "../utilities";

// Export the class itself
export class MotionSettingsController {
  constructor() {
    this.body = document.body;

    this.state = {
      reduceMotion: false,
    };

    this.classes = {
      DISABLE: "setting--reduced-motion",
    };

    this.setInitialState();

    document.addEventListener("change", (e) => {
      if (!e.target.matches(".js-motion-settings-controller")) {
        return;
      }
      const newState = !this.state.reduceMotion;
      this.update({ reduceMotion: newState });
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("reduceMotion")) {
      if (this.state.reduceMotion) {
        this.enableReducedMotion();
      } else {
        this.disableReducedMotion();
      }
    }
  }

  // Get system preferences
  getSystemSetting() {
    const reducedMotionMediaQuery = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    );
    return reducedMotionMediaQuery.matches;
  }

  setInitialState() {
    const systemSetting = this.getSystemSetting();
    const siteInititalState = util.getCookie("reduce_motion");
    if (siteInititalState === null) {
      // if reduce_motion cookie has not been activated, default to system preference
      this.update({ reduceMotion: systemSetting });
      this.setCheckboxes(systemSetting);
    } else {
      // if reduce_motion cookie has been activated, preserve that value
      // todo: we could be more sensitive here to a null value
      const siteSetting = siteInititalState === "true";
      this.update({ reduceMotion: siteSetting });
      this.setCheckboxes(siteSetting);
    }
  }

  setCheckboxes(state) {
    document
      .querySelectorAll(".js-motion-settings-controller")
      .forEach((element) => {
        element.checked = state;
      });
  }

  enableReducedMotion() {
    this.body.classList.add(this.classes.DISABLE);
    this.setCheckboxes(true);
    util.setCookie("reduce_motion", "true", 1);
    app.trigger(EVENT_REDUCED_MOTION_SETTING, { value: true });
  }

  disableReducedMotion() {
    this.body.classList.remove(this.classes.DISABLE);
    this.setCheckboxes(false);
    util.setCookie("reduce_motion", "false", 1);
    app.trigger(EVENT_REDUCED_MOTION_SETTING, { value: false });
  }
}

// Exports an array of all the current instances
export const motionSettingsController = {
  current: null,
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", () => {
    motionSettingsController.current = new MotionSettingsController();
  });
};
