/* JavaScript for AudioModule */
export class AudioPlayer {
  constructor(audio, module) {
    this.audio = audio;
    this.module = module;
    
    // State
    this.state = {
      trackId: Number(this.audio.dataset.trackId),
      progress: 0,
      duration: null,
      timeRemaining: null,
    };
    
    // Classes
    this.classes = {
      CURRENT: "--current",
    };

    // Set duration when ready
    if (this.audio.readyState > 0) {
      this.module._recordDuration();
    } else {
      this.audio.addEventListener("loadedmetadata", () => {
        this.module._recordDuration();
      });
    }

     // State tracks the native player's state
     this.audio.addEventListener("play", () => {
      if (this.state.trackId === this.module.state.currentTrack) {
        this.module._update({ paused: false });
      }
    });

    // State tracks the native player's state
    this.audio.addEventListener("pause", () => {
      if (this.state.trackId === this.module.state.currentTrack) {
        this.module._update({ paused: true });
      }
    });

    // State tracks the native player's state
    this.audio.addEventListener("timeupdate", () => {
      let progress = this.module.roundTo(this.audio.currentTime / this.audio.duration, 4);
      let timeRemaining = this.module._getTimeRemaining();

      if (this.state.trackId === this.module.state.currentTrack) {
        this.module._update({
          progress: progress,
          timeRemaining: timeRemaining,
        });

        // Trigger next track when audio ends
        if (timeRemaining === "0:00") {
          this.module._update({
            trackEnded: true,
          });
        }
      }

      this._update({
        progress: progress,
        timeRemaining: timeRemaining,
      });

    });

    this.module.scrubBack.addEventListener("click", () => {
      if (this.state.trackId != this.module.state.currentTrack) {
        return;
      }
      this.audio.currentTime = this.audio.currentTime - 15;
    })

    this.module.scrubForward.addEventListener("click", () => {
      if (this.state.trackId != this.module.state.currentTrack) {
        return;
      }
      this.audio.currentTime = this.audio.currentTime + 15;
    })
  }

  _update(update) {
    this.state = Object.assign(this.state, update);
  }
}
