import Component from "../component";
import * as util from "../../utilities";
import { ACTIVE_CLASS_NAME } from "./constants";

/*
 * Properties:
 * element
 * url
 * active
 */
export class TogglePage extends Component {
  static eventNamespace = "toggle-page";

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */
  load() {
    const url = util.editSearchParams(this.props.url, (params) => {
      params.set("remote", "toggle-page");
    });
    return util
      .fetchPageMarkup(url)
      .then((markup) => {
        const doc = util.markupToDocumentFragment(markup);
        this.elements.page.appendChild(doc);
      })
      .then(() => {
        this.update({
          loaded: true,
        });
        this.trigger("load", { target: this.props.element }, true);
        return Promise.resolve();
      });
  }

  open() {
    this.update({
      open: true,
    });
    this.trigger("open");
  }

  close() {
    this.update({
      open: true,
    });
    this.trigger("close");
  }

  setUpElements() {
    super.setUpElements();
    this.elements.page = this.props.element;
  }

  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("open")) {
      if (this.state.open) {
        this.elements.page.classList.add(ACTIVE_CLASS_NAME);
      } else {
        this.elements.page.classList.remove(ACTIVE_CLASS_NAME);
      }
    }

    return true;
  }

  get defaultState() {
    return {
      loaded: Boolean(this.props.active),
      open: Boolean(this.props.active),
      placeholderContent: this.props.element.innerHTML,
    };
  }

  get defaultEventData() {
    return {
      parent: this,
      target: this.state.page,
    };
  }
}
