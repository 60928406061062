/* JavaScript for SlideshowModule */
import app from "../ps1_app";
import { CrossfadeCarousel } from "../components/crossfade_carousel";
import { lightbox } from "../components/lightbox";

// Export the class itself
export class SlideshowModule extends CrossfadeCarousel {
  static selectorPrefix = "js-slideshow";

  static defaultConfig = Object.assign({}, CrossfadeCarousel.defaultConfig, {
    wrapAround: true,
  });

  constructor(element) {
    super(element, {});
    const self = this;

    this.lightboxSlides = element.querySelectorAll(".js-lightbox-slide");

    element.addEventListener("click", (clickEvent) => {
      if (!clickEvent.target.matches(".js-slideshow-slide img")) {
        return;
      }

      const slide = clickEvent.target.closest(".js-slideshow-slide");
      const index = self.slides.indexOf(slide);
      lightbox.current.open(self.lightboxSlides, index);
      const removeChangeListener = lightbox.current.on(
        "change",
        (changeEvent) => {
          self.select(changeEvent.index);
        }
      );
      const removeCloseListener = lightbox.current.on("close", () => {
        removeChangeListener();
        removeCloseListener();
      });
    });
  }
}

// Exports an array of all the current instances
export const slideshowModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the SlideshowModule on any given page
  app.addEventListener("pageLoad", (e) => {
    slideshowModules.current = [
      ...e.target.querySelectorAll(".js-slideshow-module"),
    ].map((instance) => new SlideshowModule(instance));
  });
};
