import app from "../ps1_app";
import * as util from "../utilities";

export class RemoteContentArea {
  constructor(element) {
    /*
    :::::::::::::::::::::
    :: GATHER ELEMENTS ::
    :::::::::::::::::::::
    */
    this.element = element;
    this.target = element.querySelector(".js-remote-content-target");
    this.loadMoreButton = element.querySelector(".js-pagination-button");

    /*
    ::::::::::::
    :: EVENTS ::
    ::::::::::::
    */
    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener("click", () => {
        this.handleLoadMore();
      });
    }

    this.dispatchEvent("initialize");
  }

  fetchAndRender(url, action, mode = "replace") {
    // Some controllers need to respond to js requests with the full page,
    // not just the paginated content. To help the controller know
    // which request wants what, we set a URL param.
    const formattedUrl = util.editSearchParams(url, (params) => {
      params.set("remote", action);
    });
    return util.fetchPageMarkup(formattedUrl).then((markup) => {
      // Create a document fragment to minimize the number of reflows needed
      let fragment = document.createRange().createContextualFragment(markup);

      // Pull out the update `next_page` link, then update/remove the "Load More" button
      fragment = this.updateLoadMoreButton(fragment);

      // At this point, the raw html has been parsed into real nodes.
      // When we append this document fragment to the DOM, the fragment
      // will be emptied out. Therefore, we save a reference to the nodes
      // now, so that we can access them even after they're appended.
      const content = [...fragment.children];

      // Dispatch an event in case any end-implementation needs to massage
      // the results further before they're added to the page
      this.dispatchEvent("beforeRender", { content });

      // Certain renders are intended to fully replace the target's content
      if (mode === "replace") {
        this.target.innerHTML = "";
      }

      // Finally, add the fetched content to the page
      this.target.appendChild(fragment);

      // Dispatch an event in case any end-implementation needs to tie-in
      this.dispatchEvent("load", { content });

      // Update the current URL
      if (mode === "replace") {
        history.replaceState(history.state, null, url);
      }
    });
  }

  handleLoadMore() {
    const url = this.loadMoreButton.getAttribute("data-url");
    return this.fetchAndRender(url, "pagination", "append");
  }

  updateLoadMoreButton(fragment) {
    if (!this.loadMoreButton) {
      return fragment;
    }

    const existingPageDataNode = this.target.querySelector(
      "[data-pagination-url]"
    );
    if (existingPageDataNode) {
      existingPageDataNode.parentNode.removeChild(existingPageDataNode);
    }

    const nextPageDataNode = fragment.querySelector("[data-pagination-url]");
    if (nextPageDataNode) {
      const nextPageUrl = nextPageDataNode.getAttribute("data-pagination-url");
      this.loadMoreButton.setAttribute("data-url", nextPageUrl);
      nextPageDataNode.parentNode.removeChild(nextPageDataNode);
      this.loadMoreButton.classList.remove("hidden");
    } else {
      this.loadMoreButton.classList.add("hidden");
    }

    return fragment;
  }

  dispatchEvent(eventType, content) {
    app.trigger(`remoteContent:${eventType}`, {
      ...content,
      target: this.target,
      remoteContentArea: this,
    });
  }
}

export const remoteContentArea = {
  current: null,
};

export const init = () => {
  // NOTE: there's a quirk with this event handler. If you arrive on a page w/ remote content, then open a modal page that also has remote content, then you *close* the modal, `current` will still point to the modal's remote content. Probably wont cause any bugs, but just be aware!
  app.addEventListener("pageLoad", (e) => {
    remoteContentArea.current = [
      ...e.target.querySelectorAll(".js-remote-content-area"),
    ].map((element) => new RemoteContentArea(element));
  });
};
