import { getFirstKeyboardFocusableElement } from "../utilities";
import focusLock from "./focus-lock";

export class MiniCalendar {
  constructor(element) {
    // Elements
    this.monthLabel = element.querySelector(".js-mini-cal-month");
    this.previousControl = element.querySelector(".js-mini-cal-previous-month");
    this.nextControl = element.querySelector(".js-mini-cal-next-month");
    this.body = element.querySelector(".js-mini-cal-body");
    this.jumpToDate = element.querySelector(".js-mini-calendar__jump-to-date");
    this.calendarUrl = element.getAttribute("data-calendar-url");
    this.calendarItems = element.querySelectorAll(".mini-calendar-day");
    // State
    this.state = {};
    // TODO: fill this out with the initial render
    this.state.label = "";
    this.state.body = "";
    this.state.previousMonthLink = "";
    this.state.nextMonthLink = "";
    this.state.focusLock = null;

    // Events
    this.events = {};

    [this.previousControl, this.nextControl].forEach((control) => {
      control.addEventListener("click", () => {
        const url = control.getAttribute("data-url");
        this.select(url);
      });
    });

    this.body.addEventListener("click", (e) => {
      e.preventDefault();

      const day = e.target.closest(".mini-calendar-day");
      const url = day.getAttribute("href");

      this.select(url);
    });

    this.jumpToDate.addEventListener("click", () => {
      getFirstKeyboardFocusableElement(this.body).focus();
      this.update({ focusLock: true });
    });
  }

  getloopTarget = () => {
    if (!document.activeElement.matches(".js-mini-cal-body *")) {
      this.calendarItems.forEach((item) => {
        item.tabIndex = "-1";
      });
    }
  };

  setFocusLock() {
    if (this.state.focusLock) {
      this.calendarItems.forEach((item) => {
        item.tabIndex = 0;
      });
      this.unlockFocus = focusLock.lock({
        selectors: [".js-mini-cal-body *"],
        loopTarget: this.getloopTarget,
      });
    } else {
      this.unlockFocus();
    }
  }

  select(url) {
    this.trigger("select", { url });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("label")) {
      this.monthLabel.textContent = this.state.label;
    }

    if (update.hasOwnProperty("body")) {
      this.body.innerHTML = this.state.body;
    }

    if (update.hasOwnProperty("previousMonthLink")) {
      this.previousControl.setAttribute(
        "data-url",
        this.state.previousMonthLink
      );
    }

    if (update.hasOwnProperty("nextMonthLink")) {
      this.nextControl.setAttribute("data-url", this.state.nextMonthLink);
    }

    if (update.hasOwnProperty("focusLock")) {
      this.setFocusLock();
    }
  }

  on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);
  }

  trigger(event, data) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((cb) => cb(data));
  }
}
