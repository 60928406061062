/* JavaScript for ImageGridModule */
import app from "../ps1_app";

// Export the class itself
export class ToTopButton {
  constructor(element) {
    this.element = element;
    const button = this.element;
    const topRefocusButton = document.querySelector(".js-top-refocus");

    button.addEventListener("click", () => {
      window.scrollTo(0, 0);
      topRefocusButton.focus();
    });
  }
}

// Exports an array of all the current instances
export const toTopButton = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    toTopButton.current = [
      ...e.target.querySelectorAll(".js-to-top-button"),
    ].map((instance) => new ToTopButton(instance));
  });
};
