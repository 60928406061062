/* JavaScript for RelatedPostsModule */

import app from "../ps1_app";
import { GenericFlickityCarousel } from "../components/generic_flickity_carousel";

const INITIALIZED_CLASS_NAME = "related-posts-carousel--initialized";

// Export the class itself
export class RelatedPostsModule extends GenericFlickityCarousel {
  static defaultConfig = Object.assign(
    {},
    GenericFlickityCarousel.defaultConfig,
    {
      cellAlign: "left",
    }
  );

  constructor(element) {
    super(element);
    this.wrapper = this.element.querySelector(".js-carousel-slide-wrapper");
    this.wrapperInitialized = this.wrapper.classList.contains(
      INITIALIZED_CLASS_NAME
    );
    this.slideSizers = [...this.slides].map((slide) => {
      return {
        slide,
      };
    });

    this.flickity.on("resize", () => {
      if (this.wrapperInitialized) {
        this.wrapper.classList.remove(INITIALIZED_CLASS_NAME);
      }
      this.setSlideSizes();
    });

    this.flickity.resize();
  }

  setSlideSizes() {
    if (!this.wrapperInitialized) {
      this.wrapper.classList.add(INITIALIZED_CLASS_NAME);
    }
  }
}

// Exports an array of all the current instances
export const relatedPostsModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the RelatedPostsModule on any given page
  app.addEventListener("pageLoad", (e) => {
    if (app.isMobile()) {
      return false;
    }

    relatedPostsModules.current = [
      ...e.target.querySelectorAll(
        ".js-related-posts-module--carousel-enabled"
      ),
    ].map((instance) => new RelatedPostsModule(instance));

    return relatedPostsModules.current;
  });
};
