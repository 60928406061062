import app from "../ps1_app";
import * as util from "../utilities";
import { MiniCalendar } from "./mini_calendar";
import { CalendarEntry } from "./calendar_entry";

class Calendar {
  constructor(element) {
    // Elements
    this.element = element;
    this.body = element.querySelector(".js-calendar-body");
    this.currentMonthLabel = element.querySelector(
      ".js-calendar-current-month-label"
    );
    this.previousMonthLabel = element.querySelector(
      ".js-calendar-previous-month-label"
    );
    this.previousMonthLink = element.querySelector(
      ".js-calendar-previous-month-link"
    );
    this.nextMonthLabel = element.querySelector(
      ".js-calendar-next-month-label"
    );
    this.nextMonthLink = element.querySelector(".js-calendar-next-month-link");
    this.miniCalButton = element.querySelector(".js-mini-cal-modal");
    this.openMiniCalButton = element.querySelector(
      ".js-calendar-open-mini-cal"
    );
    this.closeMiniCalButton = element.querySelector(
      ".js-calendar-close-mini-cal"
    );
    const miniCalendarElement = element.querySelector(".js-mini-cal");

    // Config

    // Child components
    if (miniCalendarElement) {
      this.miniCal = new MiniCalendar(miniCalendarElement);
    }

    // State
    this.state = {};
    this.state.body = this.body.innerHTML;
    this.state.currentMonthLabel = "";
    this.state.nextMonthLabel = "";
    this.state.nextMonthLink = "";
    this.state.previousMonthLabel = "";
    this.state.previousMonthLink = "";
    this.state.expanded = false;
    this.state.miniCalModal = false;

    // Events
    if (this.miniCal) {
      this.miniCal.on("select", ({ url }) => {
        this.select(url);

        if (this.state.miniCalModal) {
          this.closeMiniCalModal();
        }
      });
    }

    [this.previousMonthLink, this.nextMonthLink].forEach((control) => {
      control.addEventListener("click", (e) => {
        e.preventDefault();
        this.select(control.getAttribute("href"));
      });
    });

    // Since the calendar body is reset when the user toggles through the months
    // we need Event Delegation to capture the current toggle buttons
    document.addEventListener("click", (e) => {
      if (e.target && e.target.matches(".js-calendar-toggler")) {
        this.update({
          expanded: !this.state.expanded,
        });
        this.toggleRadioButtons(e.target);
      }
    });

    this.openMiniCalButton.addEventListener("click", () => {
      this.openMiniCalModal();
    });

    this.closeMiniCalButton.addEventListener("click", () => {
      this.closeMiniCalModal();
    });

    app.addEventListener("turbolinks:before-cache", {
      name: "calendar-clean-up",
      handler: () => {
        if (this.state.miniCalModal) {
          this.closeMiniCalModal();
        }
      },
    });
  }

  // Toggle state of radio buttons by iterating over the radio group
  // of the click target
  toggleRadioButtons(target) {
    this.radioGroup = [...target.parentNode.children];
    this.radioGroup.forEach((radio) => {
      if (this.state.expanded) {
        if (radio.matches(".js-calendar-toggler--expand-all")) {
          radio.ariaChecked = true;
        } else {
          radio.ariaChecked = false;
        }
      } else if (radio.matches(".js-calendar-toggler--collapse-all")) {
        radio.ariaChecked = true;
      } else {
        radio.ariaChecked = false;
      }
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("body")) {
      this.body.innerHTML = update.body;
    }

    if (update.hasOwnProperty("currentMonthLabel")) {
      this.currentMonthLabel.innerText = this.state.currentMonthLabel;
    }

    if (update.hasOwnProperty("previousMonthLabel")) {
      this.previousMonthLabel.innerText = this.state.previousMonthLabel;
    }

    if (update.hasOwnProperty("previousMonthLink")) {
      this.previousMonthLink.setAttribute("href", this.state.previousMonthLink);
    }

    if (update.hasOwnProperty("nextMonthLabel")) {
      this.nextMonthLabel.innerText = this.state.nextMonthLabel;
    }

    if (update.hasOwnProperty("nextMonthLink")) {
      this.nextMonthLink.setAttribute("href", this.state.nextMonthLink);
    }

    if (update.hasOwnProperty("expanded")) {
      if (this.state.expanded) {
        this.element.classList.add("calendar--expanded");
        CalendarEntry.expandAll();
        // this.expandAllButton.ariaChecked = true;
        // this.collapseAllButton.ariaChecked = false;
      } else {
        this.element.classList.remove("calendar--expanded");
        CalendarEntry.collapseAll();
        // this.expandAllButton.ariaChecked = false;
        // this.collapseAllButton.ariaChecked = true;
      }
    }

    if (update.hasOwnProperty("miniCalModal")) {
      if (this.state.miniCalModal) {
        this.miniCalButton.style.setProperty("display", "block");
        document.documentElement.classList.add("lightbox-is-open");
      } else {
        this.miniCalButton.style.removeProperty("display");
        document.documentElement.classList.remove("lightbox-is-open");
      }
    }
  }

  select(url) {
    return util.fetchJson(url).then((response) => {
      if (response.mini_calendar_body) {
        this.miniCal.update({
          body: response.mini_calendar_body,
          label: response.mini_calendar_label,
          previousMonthLink: response.previous_month_link,
          nextMonthLink: response.next_month_link,
        });
      }

      this.update({
        body: response.calendar_body,
        currentMonthLabel: response.current_month_label,
        nextMonthLabel: response.next_month_label,
        nextMonthLink: response.next_month_link,
        previousMonthLabel: response.previous_month_label,
        previousMonthLink: response.previous_month_link,
        expanded: false,
      });

      window.history.replaceState(null, null, url);

      app.trigger("calendar:load", {
        target: this.body,
      });
    });
  }

  openMiniCalModal() {
    this.update({
      miniCalModal: true,
    });
  }

  closeMiniCalModal() {
    this.update({
      miniCalModal: false,
    });
  }
}

export const calendar = {
  current: null,
};

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    const element = e.target.querySelector(".js-calendar");
    if (!element) {
      return;
    }

    calendar.current = new Calendar(element);
  });
};
