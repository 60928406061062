/* JavaScript for ImageGridModule */
import app from "../ps1_app";
import { lightbox } from "../components/lightbox";

// Export the class itself
export class ImageGridModule {
  constructor(element) {
    const images = element.querySelectorAll(".js-image-grid-image");
    const lightboxSlides = element.querySelectorAll(".js-lightbox-slide");

    [...images].forEach((image, index) => {
      image.addEventListener("click", () => {
        lightbox.current.open(lightboxSlides, index);
      });
    });
  }
}

// Exports an array of all the current instances
export const imageGridModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    imageGridModules.current = [
      ...e.target.querySelectorAll(".js-image-grid-module"),
    ].map((instance) => new ImageGridModule(instance));
  });
};
