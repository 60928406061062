import app from "../ps1_app";

/*
  This a singleton that controls repeated instances of interruptions
  on the homepage. For example, the Mythology Interruption may add a
  Vimeo embed to the page. That instance needs to be shown again and
  again as the user pages through the homepage's infinite scroll.
  We don't want to continuously add new instances of the Vimeo embed.
  Doing so would cause the following challenges:
  - Page would slow down with too many videos
  - Play/pause state would be reset with each iteration
  - Video has to be downloaded again and again

  Instead, we use the same element/js instance again and again.
  This singleton instantiates new instances of a class only when it
  is needed. Otherwise, it uses the existing instance.

  The singleton creates an IntersectionObserver, which watches for all
  interruptions' sentinels to come into view. When this happens, it
  finds the corresponding interruption, and calls `show()` on it.
  When the sentinel exits the viewport, the singleton calls `hide()`.

  Individual interruption classes should not instantiate themselves.
  Rather, they should call `flyByManager.add()` which will create the
  instance.

  Example:
    app.addEventListener("homepage:load", (e) => {
      if (!e.target.matches(MODULE_SELECTOR)) {
        return;
      }

      const element = e.target;
      const sentinel = element.querySelector(SENTINEL_SELECTOR);
      flyByManager.add(MythologyInterruptionModule, element.id, element, sentinel);
    });

  Keep in mind that repeat instances will still have HTML in the DOM.
  They just won't have javascript initialized.

  In case the interruption should look differently on repeated instances,
  the single adds a `.is-repeated-instance` CSS class to the element
  Additionally, the singleton will attempt to call `handleRepeatedInstance`
  on the interruption's main instance.

*/
class FlyByManager {
  constructor() {
    this.instances = {};
    this.sentinels = {};
    this.activeInstances = new Map();
    this.sentinelToInstanceMap = new Map();
    this.observer = new IntersectionObserver(
      (entries) => {
        this.handleIntersection(entries);
      },
      {
        threshold: [0, 1],
      }
    );
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      const instance = this.sentinelToInstanceMap.get(entry.target);
      if (!instance) {
        return;
      }

      if (entry.isIntersecting) {
        instance.show();
        this.activeInstances.set(instance, entry.target);
      } else if (this.activeInstances.has(instance)) {
        // The active instance can only be deactivated by its own sentinel.
        // The adds a safeguard so that newly added sentinels (which would trigger
        // an intersection with `isIntersecting === false`) don't deactivate
        // instances that may currently be on screen.
        const sentinelThatActivatedInstance =
          this.activeInstances.get(instance);
        if (sentinelThatActivatedInstance === entry.target) {
          instance.hide();
          this.activeInstances.delete(instance);
        }
      }
    });
  }

  add(Klass, id, element, sentinel) {
    let instance;
    if (!this.instances[id]) {
      instance = new Klass(element);
      this.instances[id] = instance;
      element.classList.add("initialized");
    } else {
      element.classList.add("is-repeated-instance");
      instance = this.instances[id];
      if (instance.handleRepeatedInstance) {
        instance.handleRepeatedInstance(element);
      }
    }
    this.sentinels[id] = sentinel;
    this.sentinelToInstanceMap.set(sentinel, instance);
    this.observer.observe(sentinel);
  }

  reset() {
    Object.values(this.sentinels).forEach((sentinel) => {
      this.observer.unobserve(sentinel);
    });
    this.instances = {};
    this.activeInstances = new Map();
    this.sentinelToInstanceMap = new Map();
  }
}

export const flyByManager = new FlyByManager();

export const init = () => {
  app.addEventListener("turbolinks:before-cache", () => {
    flyByManager.reset();
  });
};
