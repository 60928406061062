import app from "../ps1_app";

const ENTRY_SELECTOR = ".js-calendar-entry";
const TOGGLER_SELECTOR = ".js-calendar-entry-toggler";
const EXPANDED_CLASS = "calendar-entry--expanded";

export class CalendarEntry {
  static current = [];

  constructor(element) {
    // Gather elements
    this.element = element;

    // Declare state
    this.state = {};
    this.state.expanded = false;

    // Set up interactivity
    element.addEventListener("click", (e) => {
      if (!e.target.closest(TOGGLER_SELECTOR)) {
        return;
      }

      if (e.target.closest("a")) {
        e.preventDefault();
      }

      this.toggle();
    });

    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );

    // Keep track of the current instances
    this.constructor.current.push(this);
  }

  expand() {
    this.update({ expanded: true });
  }

  collapse() {
    this.update({ expanded: false });
  }

  toggle() {
    this.update({
      expanded: !this.state.expanded,
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render();
  }

  render() {
    if (this.state.expanded) {
      this.element.classList.add(EXPANDED_CLASS);
      this.element
        .querySelector(".js-calendar-entry-button")
        .setAttribute("aria-expanded", true);
    } else {
      this.element.classList.remove(EXPANDED_CLASS);
      this.element
        .querySelector(".js-calendar-entry-button")
        .setAttribute("aria-expanded", false);
    }
  }

  destroy() {
    this.update({
      expanded: false,
    });
    this.constructor.current = this.constructor.current.filter(
      (c) => c !== this
    );
    this.unlistenDestroy();
  }

  static toggleAll() {
    CalendarEntry.current.forEach((c) => c.toggle());
  }

  static expandAll() {
    CalendarEntry.current.forEach((c) => c.expand());
  }

  static collapseAll() {
    CalendarEntry.current.forEach((c) => c.collapse());
  }
}

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    const elements = [...e.target.querySelectorAll(ENTRY_SELECTOR)];
    elements.forEach((element) => new CalendarEntry(element));
  });
};
