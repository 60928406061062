import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { siteKey: String };

  initialize() {
    window.recaptchaV2 = this;
    this.element.setAttribute("id", this.id);
    try {
      grecaptcha.render(this.id, { sitekey: this.siteKey });
    } catch (e) {
      console.error("Recaptcha failed to initialize");
    }
  }

  connect() {
    console.log('Connected to RecaptchaV2 controller')
  }

  get id() {
    return this.data.get("id") || "recaptchaV2";
  }

  get siteKey() {
    return this.data.get("site-key");
  }
}
