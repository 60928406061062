/* JavaScript for MythologyInterruption */
import Player from "@vimeo/player";
import app, { EVENT_REDUCED_MOTION_SETTING } from "../ps1_app";
import { flyByManager } from "../components/fly_by_manager";
import { isEconomyEditMode } from "../utilities";
import { motionSettingsController } from "../components/motion_settings_controller";

const MODULE_SELECTOR = ".js-mythology-interruption-module";
const SENTINEL_SELECTOR = ".js-mythology-interruption-sentinel";
const PAUSED_CLASS_NAME = "mythology-interruption--paused";
const INVIEW_CLASS_NAME = "js-mythology-interruption--in-view";

// Export the class itself
export class MythologyInterruptionModule {
  constructor(magicModule) {
    // ELEMENTS
    this.magicModule = magicModule;
    this.isVideo = magicModule.classList.contains(
      "js-mythology-interruption-module-video"
    );
    this.sentinel = magicModule.querySelector(
      ".js-mythology-interruption-sentinel"
    );
    this.contents = magicModule.querySelector(
      ".js-mythology-interruption_contents"
    );

    // STATE
    this.state = {
      paused: true,
      allowAutoplay: !motionSettingsController.current.state.reduceMotion,
      repeatedInstances: [],
    };

    // INITIALIZATION
    if (this.isVideo) {
      this.setUpVideo();
    }

    // EVENTS
    this.events = {};
    this.unlistenResize = app.addEventListener("resize", () => {
      if (this.isVideo) {
        this.sizeVideo();
      }
    });

    // Destroy the instance when the user changes pages
    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );

    // INITIAL RENDER
    this.stageIntersect();
    this.pauseVideo();
  }

  // ::::::::::::::::::::::::::::
  // :: INITIALIZATION METHODS ::
  // ::::::::::::::::::::::::::::

  setUpVideo() {
    this.videoContainer = this.magicModule.querySelector(
      ".mythology-interruption__video"
    );
    this.playPauseButton = this.magicModule.querySelector(
      ".js-mythology-interruption-play-pause"
    );
    this.controls = this.magicModule.querySelector(
      ".mythology-interruption__controls"
    );
    const videoId = this.magicModule.querySelector(
      "#js-mythology-interruption__vimeo-player"
    ).dataset.id;

    const playerOptions = {
      id: videoId,
      autoplay: false,
      autopause: true,
      muted: true,
      captions: false,
      controls: false,
    };

    this.player = new Player(this.videoContainer, playerOptions);
    this.player.ready().then(() => {
      this.sizeVideo();
    });

    this.playPauseButton.addEventListener("click", () => {
      this.handlePlayPauseClick();
    });

    this.unlistenPause = app.addEventListener("pause", () => {
      this.pauseVideo();
    });

    this.unlistenPlay = app.addEventListener("play", () => {
      this.playVideo();
    });

    app.addEventListener(EVENT_REDUCED_MOTION_SETTING, (event) => {
      if (event.value) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    });
  }

  // ::::::::::::::::::::
  // :: PUBLIC METHODS ::
  // ::::::::::::::::::::

  show() {
    this.magicModule.classList.add(INVIEW_CLASS_NAME);
    this.magicModule.style.setProperty("visibility", "visible");
    this.contents.style.zIndex = "-1";
    if (
      app.autoplay &&
      !motionSettingsController.current.state.reduceMotion &&
      this.isVideo
    ) {
      this.playVideo();
    }
  }

  hide() {
    this.magicModule.classList.remove(INVIEW_CLASS_NAME);
    this.magicModule.style.setProperty("visibility", "hidden");
    this.contents.style.zIndex = "-10";
    if (this.isVideo) {
      this.pauseVideo();
    }
  }

  playVideo() {
    if (!this.isVideo) {
      return;
    }

    this.update({ paused: false });
    this.player.play();
    this.trigger("play");
  }

  pauseVideo() {
    if (!this.isVideo) {
      return;
    }

    this.update({ paused: true });
    this.player.pause();
    this.trigger("pause");
  }

  destroy() {
    if (this.player) {
      this.player.destroy();
    }

    if (this.unlistenPause) {
      this.unlistenPause();
    }

    if (this.unlistenPlay) {
      this.unlistenPlay();
    }

    this.unlistenResize();
    this.unlistenDestroy();
  }

  // :::::::::::::
  // :: EVENTS ::
  // :::::::::::::

  on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);

    return this.off.bind(this, event, handler);
  }

  off(event, handler) {
    this.events[event] = this.events[event].filter((e) => e !== handler);
  }

  trigger(event, args = {}) {
    if (!this.events[event]) {
      return;
    }

    const formattedArgs = Object.assign({}, args, {
      target: this,
    });
    this.events[event].forEach((cb) => cb.call(this, formattedArgs));
  }

  handlePlayPauseClick() {
    if (this.state.paused) {
      this.playVideo();
      app.autoplay = true;
    } else {
      this.pauseVideo();
      app.autoplay = false;
    }
  }

  // ::::::::::::::::::::::
  // :: STATE MANAGEMENT ::
  // ::::::::::::::::::::::

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  // ::::::::::::::::::::
  // :: RENDER METHODS ::
  // ::::::::::::::::::::

  stageIntersect() {
    this.contents.style.position = `fixed`;
  }

  sizeVideo() {
    const viewportAspectRatio = window.innerWidth / window.innerHeight;
    const iframe = this.player.element;
    const videoWidth = iframe.getAttribute("width");
    const videoHeight = iframe.getAttribute("height");
    const videoAspectRatio = videoWidth / videoHeight;
    const scaleToMatchViewportWidth = 1;
    const scaleToMatchViewportHeight = videoAspectRatio / viewportAspectRatio;
    const targetScale =
      viewportAspectRatio > videoAspectRatio
        ? scaleToMatchViewportWidth
        : scaleToMatchViewportHeight;
    const width = targetScale * window.innerWidth;
    const height = width / videoAspectRatio;
    iframe.style.setProperty("width", `${width}px`);
    iframe.style.setProperty("height", `${height}px`);
    this.videoContainer.style.setProperty("overflow", "hidden");
  }

  render(update) {
    if (update.hasOwnProperty("repeatedInstances")) {
      if (this.state.paused) {
        this.state.repeatedInstances.forEach((element) =>
          element.classList.add(PAUSED_CLASS_NAME)
        );
      } else {
        this.state.repeatedInstances.forEach((element) =>
          element.classList.remove(PAUSED_CLASS_NAME)
        );
      }
    }

    if (update.hasOwnProperty("paused")) {
      if (this.state.paused) {
        this.magicModule.classList.add(PAUSED_CLASS_NAME);
        this.state.repeatedInstances.forEach((element) =>
          element.classList.add(PAUSED_CLASS_NAME)
        );
      } else {
        this.magicModule.classList.remove(PAUSED_CLASS_NAME);
        this.state.repeatedInstances.forEach((element) =>
          element.classList.remove(PAUSED_CLASS_NAME)
        );
      }
    }
  }

  // ::::::::::::::::::::
  // :: FLY BY METHODS ::
  // ::::::::::::::::::::

  // When repeat instances of this module are added to the page,
  // we still want the pause button to scroll with the document.
  // Therefore we must keep its functionality & state in sync
  // with the main instance.
  handleRepeatedInstance(element) {
    if (!this.isVideo) {
      return;
    }

    // Keep track of this repeated instance in an array
    this.update({
      repeatedInstances: [...this.state.repeatedInstances, element],
    });

    // When this repeated instance's play/pause button is clicked,
    // it should update the main instance's state.
    const playPauseButton = element.querySelector(
      ".js-mythology-interruption-play-pause"
    );
    playPauseButton.addEventListener("click", () => {
      this.handlePlayPauseClick();
    });
  }
}

// Exports an array of all the current instances
export const inviewModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the MythologyInterruption on any given page
  app.addEventListener("pageLoad", (e) => {
    if (isEconomyEditMode()) {
      return;
    }

    [...e.target.querySelectorAll(MODULE_SELECTOR)].forEach((element) => {
      const sentinel = element.querySelector(SENTINEL_SELECTOR);
      flyByManager.add(
        MythologyInterruptionModule,
        element.id,
        element,
        sentinel
      );
    });
  });

  app.addEventListener("homepage:load", (e) => {
    if (!e.target.matches(MODULE_SELECTOR)) {
      return;
    }

    const element = e.target;
    const sentinel = element.querySelector(SENTINEL_SELECTOR);
    flyByManager.add(
      MythologyInterruptionModule,
      element.id,
      element,
      sentinel
    );
  });

  // When Toggle Pages are transitioning, each page receives a transform property
  // causing any fixed elements to become fixed to the transformed element
  // rather than the viewport. Applying position absolute prevents the fixed
  // content from "disappearing" from the stream.
  // Once the transition is complete, we can revert back to initial styles.

  app.addEventListener("toggle-pages:toggle-start", (e) => {
    // Collect instances of in view modules when if on the homepage/stream
    // These modules will have their "in view" class removed when the page
    // transition completes, so we store them as a variable.
    if (e.page === "stream") {
      inviewModules.current = [
        ...e.elements.container.querySelectorAll(`.${INVIEW_CLASS_NAME}`),
      ];
    }
    inviewModules.current.forEach((element) => {
      const contents = element.querySelector(
        ".js-mythology-interruption_contents"
      );
      contents.style.position = "absolute";
      contents.style.top = "0px";
    });
  });

  app.addEventListener("toggle-pages:toggle-end", () => {
    inviewModules.current.forEach((element) => {
      const contents = element.querySelector(
        ".js-mythology-interruption_contents"
      );
      contents.style.position = "fixed";
      contents.style.top = "var(--collapsed-header-height, 0)";
    });
  });
};
