import app, { EVENT_REDUCED_MOTION_SETTING } from "../ps1_app";
import * as util from "../utilities";
import { motionSettingsController } from "./motion_settings_controller";

export class Greeting {
  constructor(element) {
    // ELEMENTS
    this.elements = {};
    this.elements.container = element;
    this.elements.slides = element.querySelectorAll(".js-greetings-slide");

    // If there is only one slide, there's no need for an animation. Return early.
    if (this.elements.slides.length < 2) {
      return;
    }

    app.addEventListener(EVENT_REDUCED_MOTION_SETTING, (event) => {
      if (event.value) {
        this.stop();
      } else {
        this.start();
      }
    });

    // STATE
    this.state = {};
    this.state.index = null;
    this.state.maxIndex = this.elements.slides.length - 1;
    this.state.slide = null;
    this.state.paused = false;

    // INITIAL RENDER
    this.setup().then(() => {
      if (motionSettingsController.current.state.reduceMotion) {
        return;
      }
      this.start();
    });
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  start() {
    this.update({
      paused: false,
    });
    this.loop();
  }

  stop() {
    this.update({
      paused: true,
    });
  }

  loop() {
    const animations = [this.constructor.enterSlide(this.nextSlide)];
    if (this.state.currentSlide) {
      animations.push(this.constructor.exitSlide(this.state.currentSlide));
    }

    return Promise.all(animations)
      .then(() => {
        this.update({
          index: this.nextIndex,
          currentSlide: this.nextSlide,
        });
        return this.constructor.wait();
      })
      .then(() => {
        if (this.state.paused) {
          return false;
        }

        return this.loop();
      });
  }

  setup() {
    this.update({
      index: this.nextIndex,
      currentSlide: this.nextSlide,
    });
    [...this.elements.slides].slice(1).forEach((slide) => {
      slide.style.setProperty("visibility", "hidden");
      slide.setAttribute("aria-hidden", "true");
    });
    return this.constructor.wait();
  }

  destroy() {
    [...this.elements.slides].forEach((slide) => {
      slide.style.removeProperty("visibility");
      slide.style.removeProperty("transition");
      slide.style.removeProperty("transform");
      slide.removeAttribute("aria-hidden");
    });
  }

  /*
  ::::::::::::::::::::::
  :: STATE MANAGEMENT ::
  ::::::::::::::::::::::
  */

  update(update) {
    Object.assign(this.state, update);
  }

  /*
  :::::::::::::
  :: HELPERS ::
  :::::::::::::
  */
  get nextIndex() {
    if (typeof this.state.index !== "number") {
      return 0;
    }
    return util.loopAround(this.state.index + 1, 0, this.state.maxIndex);
  }

  get nextSlide() {
    return this.elements.slides[this.nextIndex];
  }

  static exitSlide(slide) {
    return new Promise((resolve) => {
      const handler = () => {
        slide.style.setProperty("visibility", "hidden");
        slide.setAttribute("aria-hidden", "true");
        slide.style.removeProperty("transition");
        slide.style.removeProperty("transform");
        slide.removeEventListener("transitionend", handler);
        resolve();
      };
      slide.style.setProperty("transition", "transform 500ms ease-in");
      slide.addEventListener("transitionend", handler);
      slide.style.setProperty("transform", "translateX(-100%)");
    });
  }

  static enterSlide(slide) {
    return new Promise((resolve) => {
      slide.style.setProperty("transform", "translateX(100%)");
      slide.style.setProperty("visibility", "visible");
      slide.setAttribute("aria-hidden", "false");
      // Trigger a reflow so that we can then use a transition
      slide.offsetWidth; // eslint-disable-line no-unused-expressions
      const handler = () => {
        slide.style.removeProperty("transition");
        slide.removeEventListener("transitionend", handler);
        resolve();
      };
      slide.style.setProperty("transition", "transform 500ms ease-in-out");
      slide.addEventListener("transitionend", handler);
      slide.style.setProperty("transform", "translateX(0%)");
    });
  }

  static wait(duration = 3000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
}

export const greetings = {
  current: [],
};

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    [...e.target.querySelectorAll(".js-greetings")].forEach((element) => {
      const instance = new Greeting(element);
      if (instance) {
        greetings.current.push(instance);
      }
    });
  });

  app.addEventListener("turbolinks:before-cache", () => {
    greetings.current.forEach((instance) => {
      instance.destroy();
    });
    greetings.current = [];
  });
};
