/* JavaScript for Accordion */
import app from "../ps1_app";

// Export the class itself
export class Accordion {
  constructor(element) {
    // Elements
    this.element = element;
    this.toggler = element.querySelector(".js-accordion-toggler");

    // Constants
    this.classes = {
      open: "accordion--open",
      closed: "accordion--closed",
    };

    // State
    this.state = {};
    this.state.open = element.classList.contains(this.classes.open);

    // Events
    if (this.toggler) {
      this.toggler.addEventListener("click", () => {
        if (this.state.open) {
          this.close();
        } else {
          this.open();
        }
      });
    }
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("open")) {
      if (this.state.open) {
        this.element.classList.add(this.classes.open);
        this.element.classList.remove(this.classes.closed);
        this.element.setAttribute("aria-expanded", true);
      } else {
        this.element.classList.remove(this.classes.open);
        this.element.classList.add(this.classes.closed);
        this.element.setAttribute("aria-expanded", false);
      }
    }
  }

  // Public methods
  open() {
    this.update({ open: true });
  }

  close() {
    this.update({ open: false });
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }
}

// Exports an array of all the current instances
export const accordions = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("pageLoad", (e) => {
    accordions.current = [...e.target.querySelectorAll(".js-accordion")].map(
      (instance) => new Accordion(instance)
    );
  });
};
