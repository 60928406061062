/* JavaScript for CarouselModule */

import app from "../ps1_app";
import { GenericFlickityCarousel } from "../components/generic_flickity_carousel";
import { CrossfadeCarousel } from "../components/crossfade_carousel";

// Export the class itself
export class CarouselModule extends GenericFlickityCarousel {
  static defaultConfig = Object.assign(
    {},
    GenericFlickityCarousel.defaultConfig,
    {
      cellAlign: "left",
      draggable: true,
      setGallerySize: false,
      wrapAround: true,
    }
  );

  constructor(element) {
    super(element);

    this.captionCarousel = new CrossfadeCarousel(
      element.querySelector(".js-carousel-captions"),
      {
        accessibility: false,
      }
    );

    this.slideSizers = [...this.slides].map((slide) => {
      const sizer = slide.querySelector(".js-carousel-slide-sizer");
      const aspectRatio = parseFloat(sizer.dataset.aspectRatio);
      const isArtwork = sizer.dataset.isArtwork === "true";
      return {
        slide,
        sizer,
        aspectRatio,
        isArtwork,
      };
    });

    this.setSlideSizes();
    this.flickity.resize();
  }

  select(index) {
    super.select(index);
    this.captionCarousel.select(index);
  }

  next() {
    super.next();
    this.captionCarousel.next();
  }

  previous() {
    super.previous();
    this.captionCarousel.previous();
  }

  destroy() {
    super.destroy();
    this.captionCarousel.destroy();
  }

  refreshLayout() {
    this.setSlideSizes();
    this.flickity.resize();
    this.captionCarousel.refreshLayout();
    this.trigger("layout");
  }

  setSlideSizes() {
    const carouselHeight = this.element.offsetHeight;
    // Each slide's maximum width, as measured by a factor of the component's display width
    const isMobile = app.isMobile();
    const maxWidthFactor = isMobile ? 0.9 : 0.7;
    const maxWidth = window.innerWidth * maxWidthFactor;
    this.slideSizers.forEach(({ sizer, aspectRatio, isArtwork }) => {
      const targetWidth = Math.round(carouselHeight * aspectRatio);
      const width = targetWidth > maxWidth ? maxWidth : targetWidth;
      sizer.style.setProperty("width", `${width}px`);
      sizer.style.setProperty("height", "100%");

      if (isArtwork) {
        const padding = isMobile ? "var(--gutter)" : `${width * 0.125}px`;
        sizer.style.setProperty("padding", padding);
      }
    });
  }
}

// Exports an array of all the current instances
export const carouselModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the CarouselModule on any given page
  app.addEventListener("pageLoad", (e) => {
    carouselModules.current = [
      ...e.target.querySelectorAll(".js-carousel-module"),
    ].map((instance) => new CarouselModule(instance));
  });
};
