/* JavaScript for NewsletterSignupForm */
/* global $ */
import app from "../ps1_app";

const INPUT_SCREEN = "input";
const ERROR_SCREEN = "error";
const SUCCESS_SCREEN = "success";

class NewsletterSignupForm {
  constructor(root) {
    this.element = root;
    this.form = root.querySelector(".js-newsletter-form");
    this.emailInput = root.querySelector(".js-newsletter-email");
    this.formButton = this.element.querySelector(".js-tray-newsletter__submit");
    this.screens = {};
    this.screens[INPUT_SCREEN] = root.querySelector(
      ".js-newsletter-input-screen"
    );
    this.screens[SUCCESS_SCREEN] = root.querySelector(
      ".js-newsletter-success-screen"
    );
    this.screens[ERROR_SCREEN] = root.querySelector(
      ".js-newsletter-error-screen"
    );
    this.errorMessage = root.querySelector(".js-newsletter-error");

    this.state = {
      screen: INPUT_SCREEN,
      errorMessage: null,
      email: "",
    };

    // Enable / Disable Submit button if there is value in the input
    // TODO validate based on legit email format
    this.emailInput.addEventListener("input", () => {
      const enabledClass = "js-tray-newsletter__submit--enabled";
      const submitEnabled = this.formButton.classList.contains(enabledClass);
      this.updateEmail();
      if (this.state.email !== "" && !submitEnabled) {
        this.formButton.classList.add(enabledClass);
      } else if (this.state.email === "" && submitEnabled) {
        this.formButton.classList.remove(enabledClass);
      }
    });

    const apiEndpointBase = this.form.getAttribute("action");

    this.form.addEventListener("submit", (e) => {
      // Disallow typical form submission (which results in page redirect)
      e.preventDefault();

      // TODO: replace with fetch
      $.ajax({
        type: "POST",
        url: apiEndpointBase,
        data: $(this.form).serialize(),
        error: (res) => {
          this.handleError(res);
        },
        success: () => {
          this.handleSuccess();
        },
      });
    });

    // With such a big text input, we have to truncate the placeholder text for certain viewports
    this.setPlaceholderToLength();
    app.addEventListener("resize", {
      name: "newsletterSwapPlaceholder",
      handler: () => {
        this.setPlaceholderToLength();
      },
    });

    root.addEventListener("click", (e) => {
      if (e.target.matches(".js-newsletter-retry")) {
        this.handleReset();
      }
    });
  }

  updateEmail() {
    this.state.email = this.emailInput.value;
  }

  setPlaceholderToLength() {
    let placeholder;
    if (window.innerWidth >= 1100) {
      placeholder = "Enter your email address";
    } else {
      placeholder = "Email address";
    }
    this.emailInput.setAttribute("placeholder", placeholder);
  }

  handleError(res) {
    const update = { screen: ERROR_SCREEN };
    update.errorMessage =
      res?.responseJSON?.message ||
      "Sorry, something went wrong! Please try again later.";
    this.update(update);
  }

  handleSuccess() {
    this.update({ screen: SUCCESS_SCREEN });
  }

  handleReset() {
    this.update({ screen: INPUT_SCREEN });
  }

  update(update) {
    // Merge state
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);

    if (update.hasOwnProperty("screen")) {
      this.renderScreenState(previousState);
    }

    if (update.hasOwnProperty("errorMessage")) {
      this.renderErrorMessageState(this.state.errorMessage);
    }
  }

  renderScreenState(previousState) {
    const previousScreen = this.screens[previousState.screen];
    const nextScreen = this.screens[this.state.screen];
    const endFadeInHandler = () => {
      nextScreen.style.setProperty("transition", "");
      nextScreen.setAttribute("aria-hidden", "false");
      nextScreen.removeEventListener("transitionend", endFadeInHandler);
    };
    nextScreen.addEventListener("transitionend", endFadeInHandler);

    const endFadeOutHandler = () => {
      previousScreen.style.setProperty("transition", "");
      previousScreen.setAttribute("aria-hidden", "true");
      previousScreen.style.setProperty("z-index", "-1");
      previousScreen.removeEventListener("transitionend", endFadeOutHandler);

      nextScreen.style.setProperty("transition", "opacity 300ms linear");
      nextScreen.style.setProperty("opacity", "1");
      nextScreen.style.setProperty("z-index", "1");
    };

    previousScreen.addEventListener("transitionend", endFadeOutHandler);
    previousScreen.style.setProperty("transition", "opacity 150ms linear");
    previousScreen.style.setProperty("opacity", "0");
  }

  renderErrorMessageState(message) {
    this.errorMessage.innerText = message;
  }
}

export const newsletterSignupForms = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the Tab Module on any given page
  app.addEventListener("pageLoad", () => {
    newsletterSignupForms.current = [
      ...document.querySelectorAll(".js-newsletter-signup"),
    ].map((instance) => new NewsletterSignupForm(instance));
  });
};
