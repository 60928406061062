import app from "../ps1_app";
import * as animations from "../utilities/animations";
import { GenericCarousel } from "./generic_carousel";

const INITIALIZED_CLASS_NAME = "crossfade-carousel--initialized";

/*
  NOTE: 
    At its core, this component handles two tasks:

    1. Fade in/out slides on demand
    2. Make sure the carousel's height matches the tallest slide

    Additionally, this component inherits some accessibility concerns from GenericCarousel,
    such as announcing the current slide to screen readers. Worth noting that the accessibility
    features can be turned off with any GenericCarousel instance or subclass instance.
    You may want to do this if you have two carousels working in conjunction:
    otherwise you would end up with redundant screen reader announcements.
*/
export class CrossfadeCarousel extends GenericCarousel {
  static selectorPrefix = "js-crossfade-carousel";

  constructor(element, options) {
    super(element, options);

    this.afterImagesLoad().then(() => {
      this.refreshLayout();
    });
  }

  get defaultState() {
    return Object.assign({}, super.defaultState, {
      carouselHeight: 0,
    });
  }

  refreshLayout() {
    this.update({
      carouselHeight: false,
    });
    const maxHeight = [...this.slides].reduce((runningMaxHeight, slide) => {
      const slideHeight = slide.offsetHeight;
      return slideHeight > runningMaxHeight ? slideHeight : runningMaxHeight;
    }, 0);
    this.update({
      carouselHeight: maxHeight,
    });
    this.trigger("layout");
  }

  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("index")) {
      this.renderCurrentSlide(this.state.index, previousState.index);
    }

    if (update.hasOwnProperty("carouselHeight")) {
      this.renderCarouselHeight(this.state.carouselHeight);
    }
  }

  renderCurrentSlide(currentIndex, previousIndex) {
    if (currentIndex === previousIndex) {
      return;
    }

    const currentSlide = this.slides[currentIndex];
    const previousSlide = this.slides[previousIndex];

    animations.fadeOutElement(previousSlide).then(() => {
      animations.fadeInElement(currentSlide);
    });
  }

  renderCarouselHeight(height) {
    if (height) {
      this.slideWrapper.style.height = `${height}px`;
      this.element.classList.add(INITIALIZED_CLASS_NAME);
    } else {
      this.slideWrapper.style.removeProperty("height");
      this.element.classList.remove(INITIALIZED_CLASS_NAME);
    }
  }

  /* Public methods */
  resize() {
    this.refreshLayout();
  }
}

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    // NOTE: the Image Carousel component initializes its own Crossfade Carousel.
    // This initialization is inteneded for standalone instances of the Crossfade Carousel.
    [...e.target.querySelectorAll(".js-crossfade-carousel")].map(
      (instance) => new CrossfadeCarousel(instance)
    );
  });
};
