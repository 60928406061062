/* JavaScript for RelatedProgramsModule */

import app from "../ps1_app";
import { GenericFlickityCarousel } from "../components/generic_flickity_carousel";

// Export the class itself
export class RelatedProgramsModule extends GenericFlickityCarousel {
  static selectorPrefix = "js-related-programs-module";

  static sizedClassName = "related-programs-module--sized";

  static defaultConfig = Object.assign(
    {},
    GenericFlickityCarousel.defaultConfig,
    {
      cellAlign: "left",
    }
  );

  constructor(element) {
    super(element);

    this.afterImagesLoad().then(() => {
      this.refreshLayout();
    });
  }
}

// Exports an array of all the current instances
export const relatedProgramsModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the RelatedProgramsModule on any given page
  app.addEventListener("pageLoad", (e) => {
    if (app.isMobile()) {
      return false;
    }

    relatedProgramsModules.current = [
      ...e.target.querySelectorAll(
        ".js-related-programs-module--carousel-enabled"
      ),
    ].map((instance) => new RelatedProgramsModule(instance));

    return relatedProgramsModules.current;
  });
};
