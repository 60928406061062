/* JavaScript for ImageGridModule */
import { getFirstKeyboardFocusableElement } from "../utilities";
import app from "../ps1_app";

// Export the class itself
export class SkipToContent {
  constructor(element) {
    this.element = element;
    const button = this.element;
    const baseContent = document.getElementById("base-content");
    this.firstFocusableElement = getFirstKeyboardFocusableElement(baseContent);

    button.addEventListener("click", () => {
      this.firstFocusableElement.focus();
    });
  }
}

// Exports an array of all the current instances
export const skipToContent = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    skipToContent.current = [
      ...e.target.querySelectorAll(".js-skip-to-content"),
    ].map((instance) => new SkipToContent(instance));
  });
};
