import app from "../ps1_app";

export default class Component {
  static eventNamespace = null;

  constructor(props) {
    this.props = props;
    this.setUpElements();
    this.setUpState();
    this.setUpEvents();
  }

  setUpElements() {
    this.elements = {};
  }

  setUpState() {
    this.state = this.defaultState;
  }

  setUpEvents() {
    this.events = {};
    this.unlistenDestroy = app.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.destroy();
      }
    );
  }

  on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);

    return this.off.bind(this, event, handler);
  }

  off(event, handler) {
    this.events[event] = this.events[event].filter((e) => e !== handler);
  }

  trigger(event, args = {}, emitGlobal = false) {
    const formattedArgs = Object.assign({}, this.defaultEventData, args);
    if (this.events[event]?.length) {
      this.events[event].forEach((cb) => cb.call(this, formattedArgs));
    }

    if (emitGlobal) {
      const key = this.constructor.eventNamespace
        ? `${this.constructor.eventNamespace}:${event}`
        : event;
      app.trigger(key, formattedArgs);
    }
  }

  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    return this.render(update, previousState);
  }

  render(update, previousState) {
    return true;
  }

  destroy() {
    this.unlistenDestroy();
  }

  get defaultEventData() {
    return {};
  }

  get defaultState() {
    return {};
  }
}
