import app from "../../ps1_app";
import * as animations from "../../utilities/animations";

export default class QuickLinks {
  constructor(header) {
    // PARENT
    this.header = header;

    // ELEMENTS
    this.elements = {};
    this.elements.header = header.element;
    this.elements.container = header.element.querySelector(".js-quick-links");
    this.elements.pageHeaderContent = header.element.querySelector(
      ".js-page-header-content"
    );
    this.elements.currentPageTitle = header.element.querySelector(
      ".js-expanded-page-header__title"
    );
    this.elements.currentPageDescription = header.element.querySelector(
      ".js-expanded-page-header__description"
    );
    this.elements.currentPageSecondaryDescription = header.element.querySelector(
      ".js-expanded-page-header__secondary-description"
    );
    this.elements.target = header.element.querySelector(
      ".js-quick-links-target"
    );
    this.elements.title = header.element.querySelector(
      ".js-quick-links-title-target"
    );
    this.elements.description = header.element.querySelector(
      ".js-quick-links-description-target"
    );
    this.elements.secondaryDescription = header.element.querySelector(
      ".js-quick-links-secondary-description-target"
    );
    if (!this.elements.container) {
      return;
    }

    // STATE
    this.state = {};
    this.state.description = false;
    this.state.debounceTimer = null;

    // EVENTS
    this.elements.container.addEventListener("mouseover", (e) => {
      this.handleQuickLinkMouseEnter(e);
    });
    // REVIEW: when should the quick links description deactivate? presumably when the user can no longer click?
    this.elements.container.addEventListener("mouseleave", (e) => {
      this.handleQuickLinkMouseLeave(e);
    });
    // Enable quick links interaction on tab event
    this.elements.container.addEventListener("keyup", (e) => {
      if (e.keyCode === 9) {
        this.handleQuickLinkMouseEnter(e);
      }
    });
    // When the user navigates away, clean up the state
    app.addEventListener("turbolinks:before-cache", {
      name: "quick-links-cleanup",
      handler: () => {
        this.reset();
      },
    });
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  showQuickLink(title = "", description = "", secondaryDescription = "") {
    clearTimeout(this.state.debounceTimer);
    this.update({ title, description, secondaryDescription });
  }

  reset() {
    clearTimeout(this.state.debounceTimer);
    this.update({ title: false, description: false });
  }

  /*
  ::::::::::::::::::::
  :: EVENT HANDLERS ::
  ::::::::::::::::::::
  */

  handleQuickLinkMouseEnter(e) {
    // On "mobile", the elements stack, so updating the text content
    // would cause jumping. We want to avoid that.
    if (app.isMobile()) {
      return;
    }

    // Just to be safe, don't do anything if the header is collapsed.
    // Technically this should never happen because the elements wouldn't be visible, but still
    if (!this.header.state.expanded) {
      return;
    }

    if (!e.target.matches(".js-page-header-quick-link")) {
      return;
    }

    const {
      quickLinkTitle,
      quickLinkDescription,
      quickLinkSecondaryDescription,
    } = e.target.dataset;

    if (quickLinkTitle === this.elements.currentPageTitle.innerText) {
      this.reset();
    } else {
      this.showQuickLink(
        quickLinkTitle,
        quickLinkDescription,
        quickLinkSecondaryDescription
      );
    }
  }

  handleQuickLinkMouseLeave() {
    if (app.isMobile()) {
      return;
    }

    // Commenting out because this line causes the header to occasionally hang on inccorrect categories
    // the functions that make use of this.state.description have checks to see if it is present before manipulating
    // if (!this.state.description) {
    //   return;
    // }

    this.reset();
  }

  /*
  ::::::::::::::::::::::
  :: STATE MANAGEMENT ::
  ::::::::::::::::::::::
  */

  update(update) {
    this.state.debounceTimer = setTimeout(() => {
      const previousState = Object.assign({}, this.state);
      Object.assign(this.state, update);
      this.render(update, previousState);
    }, "100");
  }

  /*
  ::::::::::::::::::::
  :: RENDER/DISPLAY ::
  ::::::::::::::::::::
  */

  render(update, previousState) {
    const hadText = previousState.title || previousState.description;
    const hasText = this.state.title || this.state.description;

    if (!hadText && hasText) {
      this.elements.pageHeaderContent.style.setProperty("display", "block");
      this.elements.target.style.setProperty("display", "none");
      animations
        .fadeOutElement(this.elements.pageHeaderContent, { duration: 100 })
        .then(() => {
          this.renderText();
          animations.fadeInElement(this.elements.target, {
            duration: 100,
          });
        });
    } else if (hasText) {
      this.renderText();
    } else if (hadText && !hasText) {
      this.elements.pageHeaderContent.style.setProperty("display", "none");
      this.elements.target.style.setProperty("display", "block");
      animations
        .fadeOutElement(this.elements.target, { duration: 100 })
        .then(() => {
          this.renderText();
          animations.fadeInElement(this.elements.pageHeaderContent, {
            duration: 100,
          });
        });
    }
  }

  renderText() {
    // If any content is in the state, render that in the Quick Links Target.
    // Otherwise, render the originally displayed page content. This won't be visible to users
    // since the element will be hidden, but it will trigger the `aria-live` to announce
    // the change back to the original content. Equal Entry requested this change.
    const hasContentToDisplay = [
      this.state.title,
      this.state.description,
      this.state.secondaryDescription
    ].some((content) => Boolean(content));
    if (hasContentToDisplay) {
      this.elements.title.innerHTML = this.state.title || "";
      this.elements.description.innerHTML = this.state.description || "";
      this.elements.secondaryDescription.innerHTML = this.state.secondaryDescription || "";
    } else {
      this.elements.title.innerHTML = this.elements.currentPageTitle?.innerHTML || "";
      this.elements.description.innerHTML = this.elements.currentPageDescription?.innerHTML || "";
      this.elements.secondaryDescription.innerHTML = this.elements.currentPageSecondaryDescription?.innerHTML || "";
    }

  }
}
