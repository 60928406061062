/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* JavaScript for Menu Sub Menus */
import app from "../../ps1_app";
import { CollapsableHeader } from "./collapsable_header";
import { Menu } from "./menu";
import QuickLinks from "./quick_links";
import { Submenu } from "./sub_menu";
import { TrayController } from "./tray_controller";

// Exports an array of all the current instances
export const pageHeader = {
  current: null,
};

export class PageHeader {
  constructor(element) {
    // ELEMENTS
    this.element = element;
    this.elements = {
      root: element,
      menu: document.querySelector(".menu-nav"),
      trayContainer: this.element.querySelector(".js-page-header__trays"),
      menuControls: document.querySelector(".js-menu-controls"),
      sentinel: document.querySelector(".js-page-header-sentinel"),
      submenuElements: document.querySelectorAll(".js-sub-menu"),
    };

    // STATE
    this.state = {};
    this.state.greetingActive = this.elements.menuControls.classList.contains(
      "header--greeting-active"
    );
    this.state.isPostPage = this.element.classList.contains(
      "page-header--post-page"
    );
    this.state.isExpandable = this.element.classList.contains(
      "js-page-header--expandable"
    );
    this.state.displayTimer = null;
    this.state.expanded = this.state.isExpandable;

    // SUB-COMPONENTS
    if (this.state.isExpandable) {
      this.collapsableHeader = new CollapsableHeader();
    }
    this.menu = new Menu(this.elements.menu, this.elements.menuControls, this);
    this.submenus = new Submenu();
    this.trayController = new TrayController(
      this.elements.trayContainer,
      this.element,
      this
    );
    this.quickLinks = new QuickLinks(this);

    // EVENTS

    // Reset header back to initial state before caching for turbolinks
    // TODO: move to a `destroy` method
    app.addEventListener("turbolinks:before-cache", {
      name: "close-header-on-link-click",
      handler: () => {
        if (this.menu.state.open) {
          this.menu.close({
            fadeOut: false,
          });
        }
      },
    });

    // Close Menu when resizing the window in order to prevent tray misalignment
    app.addEventListener("resize", {
      name: "close-menu-on-resize",
      handler: () => {
        if (this.menu.state.open) {
          this.menu.close();
        }
      },
    });

    if (this.collapsableHeader) {
      this.collapsableHeader.on("expand", () => {
        this.state.expanded = true;
        this.elements.root.classList.remove("page-header--collapsed");
        this.elements.root.classList.add("page-header--expanded");
        if (
          this.state.greetingActive &&
          !app.isMobile() &&
          !this.menu.state.open
        ) {
          this.menu.changeColorScheme("color-seafoam-green");
        }
      });

      this.collapsableHeader.on("collapse", () => {
        this.state.expanded = false;
        this.elements.root.classList.add("page-header--collapsed");
        this.elements.root.classList.remove("page-header--expanded");

        if (
          this.state.greetingActive &&
          !app.isMobile() &&
          !this.menu.state.open
        ) {
          this.menu.changeColorScheme("color-new-york-green");
        }
      });
    }

    pageHeader.current = this;
  }

  destroy() {
    pageHeader.current = null;
  }
}

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  app.addEventListener("turbolinks:load", (e) => {
    const element = e.target.querySelector(".js-page-header");
    if (!element) {
      return;
    }

    pageHeader.current = new PageHeader(element);
  });
};
