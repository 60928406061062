export const isEconomyEditMode = () =>
  document.documentElement.classList.contains("edit") ||
  document.documentElement.classList.contains("record");

// TODO: we dont support IE11, so maybe we don't need this?
export const forEachNodelist = (nodeList, callback) => {
  Array.prototype.forEach.call(nodeList, callback);
};

// TODO: we dont support IE11, so maybe we don't need this?
export const mapNodelist = (nodeList, callback) => {
  Array.prototype.map.call(nodeList, callback);
};

export const interpolate = (min, max, progress) => {
  return progress * (max - min) + min;
};

export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

export const loopAround = (val, min, max) => {
  if (val > max) {
    return min;
  }
  if (val < min) {
    return max;
  }
  return val;
};

export const isSplitContent = (element) => {
  return Boolean(element.closest(".split-content"));
};

export const fetchPageMarkup = (url) => {
  // use fetch() to request HTML from a given URL. Make sure the server knows the request is coming from an AJAX request and that the response should be HTML.
  return fetch(url, {
    headers: {
      Accept: "text/html",
      "X-Requested-With": "XMLHttpRequest",
      "X-Economy-XHR": "true",
    },
  })
    .then((response) => {
      // If the response is not OK, throw an error.
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // Otherwise, return the response.
      return response;
    })
    .then((response) => response.text());
};

export const fetchJson = (url) => {
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-Economy-XHR": "true",
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error("Could not load the requested page.", err); // eslint-disable-line no-console
    });
};

export const cookiePrefix = "_economy_ps1_";

// Copied from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript#24103596
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${cookiePrefix}${name}=${value}${expires}; SameSite=Lax; path=/`;
};

// Copied from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript#24103596
export const getCookie = (name) => {
  const nameEQ = `${cookiePrefix}${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const editSearchParams = (url, cb) => {
  // The URL interface treats relative URLs as invalid unless we pass in a base.
  const formatted =
    url.startsWith("/") || url.startsWith("?")
      ? new URL(url, window.location.origin)
      : new URL(url);
  const params = new URLSearchParams(formatted.search);
  cb(params, url);
  formatted.search = params.toString();
  return formatted.href;
};

// Fisher-Yates shuffle from Mike Bostock. See this article for documentation:
// https://bost.ocks.org/mike/shuffle/
export const shuffleArray = (array) => {
  const shuffled = array;
  let currentIndex = shuffled.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex],
    ];
  }

  return shuffled;
};

export const markupToDocumentFragment = (markup) => {
  return document.createRange().createContextualFragment(markup);
};

export const getDistanceBetweenTwoCoordinates = ([x1, y1], [x2, y2]) => {
  const a = x2 - x1;
  const b = y2 - y1;
  return Math.sqrt(a ** 2 + b ** 2);
};

export const getKeyboardFocusableElements = (element) => {
  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ),
  ].filter(
    (el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")
  );
};

export const getFirstKeyboardFocusableElement = (element) => {
  const focusableElements = getKeyboardFocusableElements(element);
  return focusableElements[0];
};
