import Component from "../component";
import app from "../../ps1_app";
import * as util from "../../utilities";

export class ToggleBar extends Component {
  /*
  ::::::::::::::::::::
  :: INITIALIZATION ::
  ::::::::::::::::::::
  */

  constructor(props) {
    super(props);
    this.links = [...this.elements.links].reduce((acc, cur) => {
      const { slug } = cur.dataset;
      acc[slug] = cur;
      return acc;
    }, {});
    this.refreshLayout();
  }

  setUpElements() {
    super.setUpElements();
    this.elements.element = this.props.element;
    this.elements.links =
      this.props.element.querySelectorAll(".js-toggle-link");
    this.elements.highlighter = this.props.element.querySelector(
      ".js-toggle-bar-highlighter"
    );

    // Apply special styles in case of single or no toggle links
    if (this.elements.links.length === 1) {
      this.elements.element.classList.add("toggle-bar--single-link");
    } else if (this.elements.links.length === 0) {
      this.elements.element.classList.add("toggle-bar--hidden");
    }
  }

  setUpState() {
    super.setUpState();
    const currentLink = [...this.elements.links].find((link) =>
      link.classList.contains("js-toggle-link--active")
    );
    this.update({
      slug: currentLink?.dataset?.slug,
      link: currentLink,
    });
    this.refreshLayout();
  }

  setUpEvents() {
    super.setUpEvents();
    this.unlistenClick = app.addEventListener("click", {
      name: "toggle-bar-select",
      handler: (e) => {
        if (!e.target.matches(".js-toggle-link")) {
          return;
        }

        e.preventDefault();

        const { slug } = e.target.dataset;
        this.highlight(slug);
        this.trigger("select", { slug }, false);
      },
    });

    // this.elements.links.forEach((link, index) => {
    //   link.addEventListener("focus", () => {
    //     // Scroll into view if located on stream or calendar page,
    //     // where the toggle bar is only visible in the collapsed header.
    //     // Additionally, only scroll into view on the fist focus instance
    //     if (
    //       index === 0 &&
    //       (this.state.slug === "stream" || this.state.slug === "calendar")
    //     ) {
    //       this.scrollIntoView();
    //     }
    //   });
    // });

    this.unlistenResize = app.addEventListener("resize", () => {
      this.refreshLayout();
    });
  }

  /*
  ::::::::::::::::::::::::::::::
  :: STATE MANAGEMENT METHODS ::
  ::::::::::::::::::::::::::::::
  */

  calculateHighlights() {
    const parentBoundingBox = this.elements.element.getBoundingClientRect();
    return [...this.elements.links].reduce((acc, cur) => {
      const { slug } = cur.dataset;
      const linkBoundingBox = cur.getBoundingClientRect();
      const width = (linkBoundingBox.width / parentBoundingBox.width) * 100;
      const height = (linkBoundingBox.height / parentBoundingBox.height) * 100;
      const x =
        ((linkBoundingBox.left - parentBoundingBox.left) /
          linkBoundingBox.width) *
        100;
      const y =
        ((linkBoundingBox.top - parentBoundingBox.top) /
          linkBoundingBox.height) *
        100;
      acc[slug] = { width, height, x, y };
      return acc;
    }, {});
  }

  calculateAnimations(highlight) {
    let translationDuration = 0;
    if (this.state.highlight?.x) {
      const distance = util.getDistanceBetweenTwoCoordinates(
        [this.state.highlight.x, this.state.highlight.y],
        [highlight.x, highlight.y]
      );
      const speed = 0.25; // % points per millsecond
      translationDuration = distance / speed; // in millseconds
    }

    const textColorDuration = translationDuration / 2;
    const textColorDelay = translationDuration - textColorDuration;
    return {
      translationDuration,
      textColorDuration,
      textColorDelay,
    };
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  /*
    Change the "active link" to the one that matches the passed in slug.
    Slug refers to the `data-slug` attribute on the link.
    Returns a promise that resolves when the animation is complete.
  */
  highlight(slug) {
    if (slug === this.state.slug) {
      return Promise.resolve();
    }

    const highlight = this.state.highlights[slug];
    const link = this.links[slug];
    return this.update({
      slug,
      link,
      highlight,
      ...this.calculateAnimations(highlight),
    });
  }

  refreshLayout() {
    const highlights = this.calculateHighlights();
    const highlight = highlights[this.state.slug];
    return this.update({
      highlights,
      highlight,
    });
  }

  destroy() {
    super.destroy();
    this.unlistenClick();
    this.unlistenResize();
  }

  /*
  ::::::::::::::::::::
  :: RENDER METHODS ::
  ::::::::::::::::::::
  */

  render(update, previousState) {
    const promises = [];
    if (update.hasOwnProperty("highlight")) {
      promises.push(this.renderHighlight(previousState));
    }

    if (update.hasOwnProperty("link")) {
      promises.push(this.renderLink(previousState));
    }

    return promises.length ? Promise.allSettled(promises) : Promise.resolve();
  }

  renderLink(previousState) {
    const { link: currentLink, textColorDuration, textColorDelay } = this.state;
    const { link: previousLink } = previousState;

    return new Promise((resolve) => {
      if (previousLink) {
        currentLink.style.setProperty(
          "transition",
          `color ${textColorDelay}ms linear ${textColorDuration}ms`
        );
        previousLink.classList.remove(
          "js-toggle-link--active",
          "link-button--selected"
        );
      }

      currentLink.style.setProperty(
        "transition",
        `color ${textColorDuration}ms linear ${textColorDelay}ms`
      );
      currentLink.classList.add(
        "js-toggle-link--active",
        "link-button--selected"
      );

      resolve();
    });
  }

  renderHighlight() {
    return new Promise((resolve) => {
      const { width, height, x, y } = this.state.highlight;
      this.elements.highlighter.style.setProperty(
        "transition",
        `all ${this.state.translationDuration}ms ease-in-out`
      );
      // TODO: this handler should check for all transitions to end. Or maybe just use WebAnimations API
      const handler = () => {
        resolve();
        this.elements.highlighter.removeEventListener("transitionend", handler);
      };
      this.elements.highlighter.addEventListener("transitionend", handler);
      this.elements.highlighter.style.setProperty("width", `${width}%`);
      this.elements.highlighter.style.setProperty("height", `${height}%`);
      this.elements.highlighter.style.setProperty(
        "transform",
        `translate(${x}%, ${y}%)`
      );
    });
  }

  scrollIntoView() {
    const scrollOffset = app.state.expandedHeaderHeight;
    window.scrollTo(0, scrollOffset);
  }

  /*
  ::::::::::::::::::::
  :: HELPER METHODS ::
  ::::::::::::::::::::
  */

  get defaultState() {
    return {
      slug: null,
      link: null,
      highlight: {},
      highlights: {},
      translationDuration: 0,
      textColorDuration: 0,
      textColorDelay: 0,
    };
  }
}
