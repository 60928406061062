export const showElement = (element, options = {}) => {
  const { top = "0px", position = "fixed" } = options;
  element.style.setProperty("z-index", "33");
  element.style.setProperty("display", "block");
  element.style.setProperty("position", position);
  element.style.setProperty("top", top);
};

export const hideElement = (element, display = "block") => {
  element.offsetWidth; // eslint-disable-line no-unused-expressions
  element.style.setProperty("z-index", "-1");
  element.style.setProperty("display", display);
};
