import Component from "../component";
import app from "../../ps1_app";
import { TogglePage } from "./toggle_page";
import { ToggleBar } from "./toggle_bar";
import { collapsableHeader } from "../header/collapsable_header";

export class TogglePages extends Component {
  static eventNamespace = "toggle-pages";
  /*
  ::::::::::::::::::::
  :: INITIALIZATION ::
  ::::::::::::::::::::
  */

  constructor(props) {
    super(props);

    // If there is a navigation bar in the header with toggle links,
    // instantiate a class that will control the links' animations.
    this.toggleBars = [];
    [...this.elements.toggleBars].forEach((toggleBar) => {
      const instance = new ToggleBar({ element: toggleBar });
      this.toggleBars.push(instance);
      instance.on("select", (e) => {
        this.open(e.slug);
      });
    });
  }

  setUpElements() {
    super.setUpElements();

    const { container } = this.props;
    this.elements.container = container;
    this.elements.pages = container.querySelectorAll(".js-toggle-page");
    this.elements.startingPage = container.querySelector(
      ".js-toggle-page--active"
    );
    this.elements.toggleBars = document.querySelectorAll(".js-toggle-bar");
  }

  setUpState() {
    super.setUpState();
    const startingPageSlug = this.elements.startingPage.dataset.slug;
    this.state.pages = [...this.elements.pages].reduce(
      (pages, element, index) => {
        const { slug, url } = element.dataset;
        const active = slug === startingPageSlug;
        pages[slug] = {
          element,
          index,
          instance: new TogglePage({ url, element, active }),
          scrollPosition: null,
        };
        return pages;
      },
      {}
    );
    this.state.slug = startingPageSlug;
    this.state.index = this.state.pages[startingPageSlug].index;
  }

  setUpEvents() {
    super.setUpEvents();

    this.unlistenPopState = app.addEventListener("popstate", {
      name: "toggle-bar-history",
      handler: (e) => {
        if (!e.state.isTogglePage) {
          return;
        }

        this.open(e.state.slug, { updateURL: false });
      },
    });
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  /*
    Change the currently visible page to the one matching the passed-in slug.
    Slug here refers to the string that was used to identify the page when
    this component was instantiated.
    Optionally control if we should force the Toggle Bar to stay in sync.
    Optionally control if we should add an entry to the browser history.
    Returns a promise that resolves when the page is fully on display
  */
  open(slug, options = {}) {
    const { updateURL = true } = options;
    const page = this.state?.pages[slug];

    if (!page || this.state.slug === slug) {
      return Promise.resolve();
    }

    // If the next page has not yet been loaded, request it.
    // Otherwise return instantly-resolved promise so that we can appropriately
    // chain subsequent actions with `then()`
    const needToLoadPage = !page.instance.state.loaded;
    const stepOne = () => {
      if (needToLoadPage) {
        return page.instance.load();
      }
      return Promise.resolve();
    };

    // Before we switch pages, record how far the user has scrolled.
    // We'll want to restore that later.
    const currentPage = this.state.pages[this.state.slug];
    currentPage.scrollPosition = window.scrollY;

    // If the next page has never been loaded, then we should scroll
    // the user to the top of the page. Record that value so it can be used later.
    if (needToLoadPage) {
      page.scrollPosition = Math.ceil(
        this.elements.container.getBoundingClientRect().top -
          app.state.dynamicHeaderHeight +
          window.scrollY +
          2
      );
    }

    // The toggle action will change the user's scroll position. We don't want the
    // header to switch between expanded/collapsed states when that happens,
    // because it looks jumpy. Therefore we suspend its scroll behavior.
    if (collapsableHeader.current) {
      collapsableHeader.current.suspend();
    }

    this.trigger(
      "toggle-start",
      { elements: this.elements, page: this.state.slug },
      true
    );

    const promise = stepOne()
      .then(() => {
        return this.update({
          slug,
          index: page.index,
        });
      })
      .then(() => {
        // Now that the toggle animation is finished, we can unsuspend the header's scroll behavior.
        // Unfortunately, we cannot guarantee that the scroll has changed yet, even though we're using
        // a promise. We absolutely need to wait for the scroll to finish before we unsuspend.
        // Therefore, we have to put a small timeout.
        if (collapsableHeader.current) {
          setTimeout(() => {
            collapsableHeader.current.unsuspend();
          }, 100);
        }

        // If the user is navigating via the browser's back/forward buttons,
        // and the target of that navigation is one of our toggle pages,
        // then we use the `open()` to handle it. Since this is already a history
        // action, we do not want to add an entry to the history.
        // Therefore we return early.
        if (!updateURL) {
          return;
        }
        history.pushState(
          { isTogglePage: true, slug },
          null,
          page.instance.props.url
        );
      })
      .then(() => {
        this.trigger(
          "toggle-end",
          { elements: this.elements, page: this.state.slug },
          true
        );
      });

    // This is a public method, so other components beyond the Toggle Bar
    // can potentially call it. In those situations, we still want the Toggle Bar
    // to show the active link, so we call this action on it.
    if (this.toggleBars.length) {
      this.toggleBars.forEach((toggleBar) => {
        toggleBar.highlight(slug);
      });
    }

    return promise;
  }

  refreshLayout() {
    if (!this.toggleBars.length) {
      return;
    }

    this.toggleBars.forEach((toggleBar) => {
      toggleBar.refreshLayout();
    });
  }

  destroy() {
    super.destroy();
  }

  /*
  ::::::::::::::::::::
  :: RENDER METHODS ::
  ::::::::::::::::::::
  */

  render(update, previousState) {
    // Keep track of all rendering as a promises so that other actions can
    // be executed in sequence.
    const promises = [];

    if (update.hasOwnProperty("slug")) {
      const direction =
        this.state.index > previousState.index ? "left" : "right";
      const previousPage = this.state.pages[previousState.slug];
      const currentPage = this.state.pages[this.state.slug];

      // All pages will be made `position: absolute`. In order to prevent layout jumping,
      // make the container have a fixed height until the animation is finished
      this.elements.container.style.removeProperty("height");
      this.elements.container.style.setProperty(
        "height",
        `${this.elements.container.offsetHeight}px`
      );
      this.elements.container.style.setProperty("position", "relative");

      this.elements.container.offsetWidth; // eslint-disable-line no-unused-expressions

      // Slide the previous page and the newly current page in
      const slideOutPromise = this.constructor.slideOut(
        previousPage.element,
        direction
      );
      const slideInPromise = this.constructor.slideIn(
        currentPage.element,
        direction,
        currentPage.scrollPosition,
        this.elements.container
      );

      promises.push(slideOutPromise);
      promises.push(slideInPromise);
    }

    return Promise.allSettled(promises);
  }

  /*
  :::::::::::::
  :: HELPERS ::
  :::::::::::::
  */

  get defaultState() {
    return {
      slug: null,
      pages: [],
      index: null,
    };
  }

  static slideIn(element, direction, scrollPosition, container) {
    return new Promise((resolve) => {
      // We want the incoming page to be scrolled to the same point where the user left it
      // (or if this page has not yet been seen, we want to the page scrolled to the top)
      // but we cannot just adjust the window's scroll position, as that would scroll
      // away the outgoing page. Therefore, we do a little trick where we translate
      // the incoming page vertically, slide it in horizontally, then when it's done,
      // we get rid of the vertical translation & set the scroll position.
      const startingTranslationX = direction === "left" ? "100%" : "-100%";
      const startingTranslationY = `${window.scrollY - scrollPosition}px`;
      element.style.setProperty("position", "absolute");
      element.style.setProperty("top", "0");
      element.style.setProperty("left", "0");
      element.style.setProperty(
        "transform",
        `translate(${startingTranslationX}, ${startingTranslationY})`
      );
      element.style.setProperty("display", "block");
      // Trigger a reflow so that we can then use a transition
      element.offsetWidth; // eslint-disable-line no-unused-expressions
      // Clean up after the transition ends
      const handler = () => {
        element.style.removeProperty("position");
        element.style.removeProperty("top");
        element.style.removeProperty("left");
        element.style.removeProperty("transition");
        element.removeEventListener("transitionend", handler);
        container.style.removeProperty("height");
        document.documentElement.scrollTo(0, scrollPosition);
        element.style.removeProperty("transform");
        resolve();
      };
      element.addEventListener("transitionend", handler);
      element.style.setProperty("transition", `transform 600ms ease-in-out`);
      element.style.setProperty(
        "transform",
        `translate(0, ${startingTranslationY})`
      );
    });
  }

  static slideOut(element, direction) {
    return new Promise((resolve) => {
      const endingTranslation = direction === "left" ? "-100%" : "100%";
      element.style.setProperty("display", "block");
      element.style.setProperty("position", "absolute");
      element.style.setProperty("top", "0");
      element.style.setProperty("left", "0");
      element.style.setProperty("translate", `translateX(0)`);

      // Trigger a reflow so that we can then use a transition
      element.offsetWidth; // eslint-disable-line no-unused-expressions

      // Clean up after the transition ends
      const handler = () => {
        element.removeEventListener("transitionend", handler);
        element.style.setProperty("display", "none");
        element.style.removeProperty("position");
        element.style.removeProperty("top");
        element.style.removeProperty("left");
        element.style.removeProperty("transition");
        element.style.removeProperty("transform");
        resolve();
      };
      element.addEventListener("transitionend", handler);

      element.style.setProperty("transition", `transform 600ms ease-in-out`);
      element.style.setProperty(
        "transform",
        `translateX(${endingTranslation})`
      );
    });
  }
}

export const togglePages = {
  current: null,
};

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    const container = e.target.querySelector(".js-toggle-pages");
    if (!container) {
      return;
    }

    togglePages.current = new TogglePages({ container });
  });
};
