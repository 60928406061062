/* JavaScript for WallpaperModule */
import app from "../ps1_app";

// Export the class itself
export class EmbeddedVideo {
  constructor(element) {
    this.element = element;
    this.module = this.element.closest(".magic-module");
    this.embedCode = this.element.getAttribute("data-embed-code");
    this.autoInit = this.element.getAttribute("data-auto-init");

    if (this.autoInit) {
      this.initializeIframe();
    }

    this.element.addEventListener("click", () => {
      this.initializeIframe();
    });
  }

  initializeIframe() {
    this.element.innerHTML = this.embedCode;
    this.module.classList.add("video-module--embed-code-initialized");
  }
}

// Exports an array of all the current instances
export const embeddedVideos = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the WallpaperModule on any given page
  app.addEventListener("pageLoad", (e) => {
    embeddedVideos.current = [
      ...e.target.querySelectorAll(".video-module__video-wrapper"),
    ].map((instance) => new EmbeddedVideo(instance));
  });
};
