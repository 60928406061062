import app from "../ps1_app";
import * as util from "../utilities";

/*
  This component helps with accessibility when we need to make an
  entire block of content a link.

  Generally speaking, we don't want to wrap entire blocks of content
  in an <a> tag. If you do, a screen reader will announce the whole
  block at once. 

  Instead, we wrap a meaninful span of tag in the <a> and use this
  JS to add a "click" handler. 

  To use, apply the `.js-block-level-link` to the element you want
  to be clickable. This element should contain an <a> tag.

    <div class="js-block-level-link">
      <!-- other stuff here -->
      <a href="/home">Go to the homepage</a>
      <!-- other stuff here -->
    </div>

  NOTE: we don't use a click handler because we want to preserve
  the ability to select text. For more on this approach, see this
  article from Inclusive Components: https://inclusive-components.design/cards/
*/

class BlockLevelLink {
  constructor(element) {
    const anchorTag = element.querySelector("a[href]");

    if (!anchorTag) {
      return;
    }

    let mouseDownTime;

    element.addEventListener("mousedown", () => {
      mouseDownTime = new Date().getTime();
    });

    element.addEventListener("mouseup", () => {
      if (!mouseDownTime) {
        return;
      }

      const currentTime = new Date().getTime();
      if (currentTime - mouseDownTime < 200) {
        anchorTag.click();
      }

      mouseDownTime = null;
    });
  }
}

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    if (util.isEconomyEditMode()) {
      return;
    }

    const blockLevelLinks = e.target.querySelectorAll(".js-block-level-link");
    [...blockLevelLinks].map((element) => {
      return new BlockLevelLink(element);
    });
  });
};
