/* JavaScript for Menu Sub Menus */
import focusLock from "../focus-lock";
import app from "../../ps1_app";
import * as animations from "../../utilities/animations";
import { getFirstKeyboardFocusableElement } from "../../utilities";

export class Menu {
  constructor(menu, menuControls, header) {
    this.menu = menu;
    this.menuControls = menuControls;
    this.header = header;
    this.toggleButton = this.menuControls.querySelector(
      ".js-menu-controls__expand-collapse"
    );
    this.firstFocusableElement = getFirstKeyboardFocusableElement(this.menu);

    this.state = {
      open: false,
      persistantColor: this.menuControls.getAttribute("data-color"),
      currentColor: this.menuControls.getAttribute("data-color"),
      isMobile: app.isMobile(),
    };

    this.toggleButton.addEventListener("click", () => {
      // Close any open trays before opening or closing the menu
      // to prevent alignment errors
      this.header.trayController.close();
      if (!this.state.open) {
        this.open();
      } else {
        this.close();
      }
    });

    app.addEventListener("breakpoint", (e) => {
      const prevDesktop = ["lg", "md"].indexOf(e.previous) >= 0;
      const nextMobile = ["sm", "xs"].indexOf(e.current) >= 0;
      if (this.header.state.greetingActive && this.header.state.expanded) {
        if (prevDesktop && nextMobile) {
          this.changeColorScheme("color-new-york-green");
        } else if (!prevDesktop && !nextMobile) {
          this.changeColorScheme("color-seafoam-green");
        }
      }
    });
  }

  changeColorScheme(nextColor) {
    this.menuControls.classList.remove(this.state.currentColor);
    this.menuControls.classList.add(nextColor);
    this.state.currentColor = nextColor;
  }

  // Update styles attach classes to menucontrols
  toggleMenuIcon() {
    if (!this.state.open) {
      this.menuControls.classList.remove("menu-controls--collapse-active");
      this.menuControls.classList.add("menu-controls--expand-active");
      this.toggleButton.setAttribute("aria-expanded", false);
    } else {
      this.menuControls.classList.remove("menu-controls--expand-active");
      this.menuControls.classList.add("menu-controls--collapse-active");
      this.toggleButton.setAttribute("aria-expanded", true);
    }
  }

  hideControls() {
    this.menuControls.style.display = "none";
  }

  showControls() {
    if (app.isMobile) {
      this.menuControls.style.display = "flex";
    } else {
      this.menuControls.style.display = "block";
    }
  }

  getloopTarget = () => {
    // We need this here because there are multiple instanced of focusLock
    // being called. If the active element matches our other focusLock element,
    // return null
    // TODO: There should be a more elegant solution for this, where one instance
    // would pause until the other unlocks depending on heiarchy.
    if (!document.activeElement.matches(".js-sub-menu--toggle")) {
      if (document.activeElement.matches("#application-yield *")) {
        return this.toggleButton;
      }
      return this.firstFocusableElement;
    }
    return null;
  };

  setFocusLock() {
    if (this.state.open) {
      this.unlockFocus = focusLock.lock({
        selectors: [".js-menu-nav *", ".js-menu-controls__expand-collapse"],
        loopTarget: this.getloopTarget,
      });
    } else {
      this.unlockFocus();
    }
  }

  open() {
    this.state.open = true;
    this.header.element.classList.add("menu-nav--open");
    this.menu.classList.add("menu-nav--open");
    this.menu.setAttribute("aria-expanded", true);
    this.setFocusLock();
    animations.fadeInElement(this.menu, {
      duration: 300,
    });
    this.toggleMenuIcon();
    app.setMenuHeight();
    app.setTrayOffset({ menuOpen: true });
    this.changeColorScheme("color-new-york-green");
    if (this.header.state.greetingActive) {
      this.changeColorScheme("color-new-york-green");
    }
  }

  close(options = {}) {
    const { fadeOut = true } = options;
    this.state.open = false;
    this.header.element.classList.remove("menu-nav--open");
    this.menu.classList.remove("menu-nav--open");
    this.menu.setAttribute("aria-expanded", false);
    this.setFocusLock();

    if (fadeOut) {
      animations.fadeOutElement(this.menu, {
        duration: 300,
      });
    } else {
      this.menu.style.setProperty("display", "none");
      this.menu.style.setProperty("opacity", 0);
    }
    this.toggleMenuIcon();
    app.setTrayOffset({ menuOpen: false });
    if (
      !this.state.isMobile &&
      this.header.state.greetingActive &&
      this.header.state.expanded
    ) {
      this.changeColorScheme("color-seafoam-green");
    }
    if (this.header.state.isPostPage) {
      this.changeColorScheme(this.state.persistantColor);
    }
  }

  on(name, handler) {
    if (!this.events[name]) {
      this.events[name] = [];
    }

    this.events[name].push(handler);
  }

  removeListener(name, listenerToRemove) {
    if (!this.events[name]) {
      return;
    }

    const filterListeners = (listener) => listener !== listenerToRemove;
    this.events[name] = this.events[name].filter(filterListeners);
  }

  emit(name, data) {
    if (!this.events[name]) {
      return;
    }

    this.events[name].forEach((handler) => handler(data));
  }
}
