/*
  This class is used to control keyboard focus and keep it contained
  within a set of constraints. It is used to add accessibility to dialogs,
  so that users are not focusing elements that are outside of the dialog.

  "Constraints" are enforced whenever the users changes focus. If the currently focused
  element does not meet the constraints, then focus is redirected back to the `loopTarget`.
  
  Constraints can either be selectors that the element must match, or they can be
  HTML elements that must either *be* the currently focused element or *contain* it.

  This class has support for a tracked "history" of constraints (each of which is called an "entry").
  This allows you open a second focus lock while one is already open. When the second one is
  unlocked, the first one will be made active again.

  This class is exported as a singleton so that its state can be shared
  across several parts of the app.

  Example:
    const focusableElements = document.querySelectorAll(".focusable-element");
    // Locks the focus
    const unlock = focusLock.lock({
      elements: [...focusableElements],
      loopTarget: focusableElements[0]
    })

    // Unlocks the focus
    unlock();

  Example:
    focusLock.lock({
      selectors: [".modal *", ".some-other-selector"],
      loopTarget: document.getElementById("modal-close-button")
    })

  Using Conditional a Loop Target:
  The loopTarget parameter can accept a function, which is useful in circumstances
  where you want to set a target vlaue depending on a condition.

  Example:
  getloopTarget = () => {
    const someOtherElement = document.querySelectorAll(".other-element");
    if (document.activeElement.matches("#application-yield *")) {
      return someOtherElement;
    }
    return focusableElements[0];
  };

  focusLock.lock({
    selectors: [".selector *"],
    loopTarget: this.getloopTarget,
  });

  TODO:
  - Support moving in reverse with shift+tab
  - If you call `unlock` on a entry that isn't the currently active, everything blows up
*/
import app from "../ps1_app";

class FocusLock {
  constructor() {
    this.state = Object.assign({}, this.constructor.defaultState);

    app.addEventListener("focusin", {
      name: "focus-lock-handler",
      handler: () => {
        this.handleFocusChange();
      },
    });

    app.addEventListener("turbolinks:before-cache", {
      name: "focus-lock-teardown",
      handler: () => {
        this.unlockAll();
      },
    });
  }

  /**
   * @param   {FocusLock~Entry}   entry   A configuration object that describes how the lock should be enforced
   * @returns {Function}  A function that will unlock this lock and remove it from the history
   * @typedef   {Object}  FocusLock~Entry
   * @property    {[string]}                [selectors]       Selectors that the currently focused element must match in order for focus to be permissible. If it's not permissible, the `loopTarget` will be focused. Required if `elements` is not present
   * @property    {[HTMLElement]}           [elements]        Elements that the currently focused element must match or be a child to in order for focus to be permissible. If it's not permissible, the `loopTarget` will be focused. Required if `selectors` is not present
   * @property    {HTMLElement|Function}    loopTarget        An element that will receive focus when the user hits tab and ends up on an unpermitted focus (i.e. where should we redirect focus when the user tabs past the end of the available options). Alternatively, pass in a function that returns an element.
   * @property    {HTMLElement|Function}    [lockTarget]      An element that will receive focus when the lock is set. Alternatively, pass in a function that returns an element.
   * @property    {HTMLElement|Function}    [unlockTarget]    An element that will receive focus when the lock is unset. If the lock is unset and another entry exists, the focus will not be directed here, but rather to the newly active entry's `lockTarget`. Alternatively, pass in a function that returns an element.

  */
  lock(entry) {
    // Validate the entry
    if (!entry.loopTarget) {
      throw new Error("Focus Lock: expected parameter `loopTarget`");
    }

    if (!entry.elements && !entry.selectors) {
      throw new Error(
        "Focus Lock: expected parameter `elements` or `selectors`"
      );
    }

    if (
      entry.elements &&
      (!Array.isArray(entry.elements) ||
        entry.elements.some((element) => element.nodeType !== 1))
    ) {
      throw new Error(
        "Focus Lock: `elements` parameter must be an array of HTML nodes"
      );
    }

    if (
      entry.selectors &&
      (!Array.isArray(entry.selectors) ||
        entry.selectors.some((selector) => typeof selector !== "string"))
    ) {
      throw new Error(
        "Focus Lock: `selectors` parameter must be an array of strings"
      );
    }

    const parsedEntry = Object.assign({}, entry);
    if (!parsedEntry.unlockTarget) {
      parsedEntry.unlockTarget = document.activeElement;
    }

    if (!parsedEntry.lockTarget) {
      parsedEntry.lockTarget = entry.loopTarget;
    }

    // Add it to our saved list of entries
    this.update({
      entries: [...this.state.entries, parsedEntry],
    });

    // Totally clear out the previously active entry
    this.update({
      locked: false,
      selectors: [],
      elements: [],
      loopTarget: null,
      unlockTarget: null,
      lockTarget: null,
    });

    // Set the current lock
    this.update({
      locked: true,
      ...parsedEntry,
    });

    // Set the initial focus
    this.constructor.focusElementOrFunction(this.state.lockTarget);

    // Make it easy for devs to remove this lock by returning a function they can call
    return this.unlock.bind(this, parsedEntry);
  }

  unlock(entry) {
    if (!this.state.locked || !this.state.entries.length) {
      return;
    }

    let entryToUse = entry;
    if (!entry) {
      entryToUse = this.state.entries[this.state.entries.length - 1];
    } else if (!this.state.entries.some((e) => e === entry)) {
      // Don't do anything if this entry isn't in our store
      return;
    }

    // Remove the entry from our store and update the current lock.
    // If there's another entry to use, lock to that.
    this.update({
      entries: this.state.entries.filter((e) => e !== entryToUse),
    });

    if (this.state.entries.length) {
      // Totally clear out the previously active entry
      this.update({
        locked: false,
        selectors: [],
        elements: [],
        loopTarget: null,
        focusToReturnTo: null,
      });

      // Get the most recently added entry from the store
      const activeEntry = this.state.entries.slice().pop();
      this.update({
        locked: true,
        ...activeEntry,
      });

      this.constructor.focusElementOrFunction(this.state.lockTarget);
    } else {
      this.constructor.focusElementOrFunction(this.state.unlockTarget);
      this.update(this.constructor.defaultState);
    }
  }

  unlockAll() {
    if (!this.state.locked || !this.state.entries.length) {
      return;
    }

    const { unlockTarget } = this.state.entries[0];
    this.update(this.constructor.defaultState);
    this.constructor.focusElementOrFunction(unlockTarget);
  }

  handleFocusChange() {
    if (!this.state.locked) {
      return;
    }

    let isPermissible = true;
    if (this.state.selectors.length) {
      isPermissible = this.state.selectors.some((selector) =>
        document.activeElement.matches(selector)
      );
    } else if (this.state.elements.length) {
      isPermissible = this.state.elements.some(
        (element) =>
          element === document.activeElement ||
          element.contains(document.activeElement)
      );
    }

    if (isPermissible) {
      return;
    }

    this.constructor.focusElementOrFunction(this.state.loopTarget);
  }

  update(update) {
    Object.assign(this.state, update);
  }

  static defaultState = {
    entries: [],
    locked: false,
    selectors: [],
    elements: [],
    loopTarget: null,
    lockTarget: null,
    unlockTarget: null,
  };

  static focusElementOrFunction(target) {
    if (target instanceof Function) {
      const element = target();
      if (element && element.nodeType === 1) {
        element.focus();
      }
    } else if (target.nodeType === 1) {
      target.focus();
    }
  }
}

// Export a singleton
export default new FocusLock();
