import app from "../../ps1_app";
import * as animations from "../../utilities/animations";
import * as headerUtilities from "./utilities";
import Component from "../component";

export const collapsableHeader = {
  current: null,
};

export class CollapsableHeader extends Component {
  constructor() {
    super();

    this.observer = new IntersectionObserver(
      (entries) => {
        this.handleIntersection(entries);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: Array.from({ length: 100 }).map((_, i) => i / 100),
      }
    );

    this.observer.observe(this.elements.sentinel);

    collapsableHeader.current = this;
  }

  setUpElements() {
    super.setUpElements();
    this.elements.collapsedHeader = document.querySelector(
      ".js-collapsed-page-header"
    );
    this.elements.sentinel = document.querySelector(".js-page-header-sentinel");
  }

  get defaultState() {
    return {
      expanded: true,
      scrollPosition: window.scrollY,
      suspended: false,
      initialized: false,
    };
  }

  handleIntersection([sentinel]) {
    if (this.state.suspended) {
      return;
    }

    const { y: currentScrollPosition } = sentinel.boundingClientRect;
    const { scrollPosition: previousScrollPosition } = this.state;
    const scrollDirection =
      previousScrollPosition < currentScrollPosition ? "up" : "down";
    this.state.scrollPosition = currentScrollPosition;

    if (!this.state.initialized) {
      if (sentinel.boundingClientRect.top < 0) {
        this.collapse();
      } else {
        this.expand();
      }
      this.update({
        initialized: true,
      });
    } else if (this.state.expanded && scrollDirection === "down") {
      this.collapse();
    } else if (!this.state.expanded && scrollDirection === "up") {
      this.expand();
    }
  }

  expand() {
    this.update({
      expanded: true,
    });
    animations
      .fadeOutElement(this.elements.collapsedHeader, { duration: 300 })
      .then(() => {
        // If state has changed during fadeOutElement, prevent method completion
        if (!this.state.expanded) {
          return;
        }
        headerUtilities.hideElement(this.elements.collapsedHeader);
        app.setDynamicHeaderHeight();
      });
    this.trigger("expand");
  }

  collapse() {
    this.update({
      expanded: false,
    });
    headerUtilities.showElement(this.elements.collapsedHeader);
    animations
      .fadeInElement(this.elements.collapsedHeader, {
        duration: 300,
      })
      .then(() => {
        app.setDynamicHeaderHeight();
      });
    this.trigger("collapse");
  }

  suspend() {
    this.update({
      suspended: true,
    });
  }

  unsuspend() {
    this.update({
      suspended: false,
    });
  }

  destroy() {
    super.destroy();
    this.observer.disconnect();
    this.unsuspend();
    collapsableHeader.current = null;
  }
}
