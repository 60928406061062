/* JavaScript for TableModule */
import app from "../ps1_app";

// Export the class itself
export class TableModule {
  constructor(element) {
    // Elements
    this.element = element;
    this.toggler = element.querySelector(".js-table-toggler");

    // Constants
    this.classes = {
      open: "table-module--open",
      closed: "table-module--truncated",
    };

    // State
    this.state = {};
    this.state.open = element.classList.contains(this.classes.open);

    // Events
    if (this.toggler) {
      this.toggler.addEventListener("click", () => {
        if (this.state.open) {
          this.close();
        } else {
          this.open();
        }
      });
    }
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("open")) {
      if (this.state.open) {
        this.element.classList.add(this.classes.open);
        this.element.classList.remove(this.classes.closed);
      } else {
        this.element.classList.remove(this.classes.open);
        this.element.classList.add(this.classes.closed);
      }
    }
  }

  // Public methods
  open() {
    this.update({ open: true });
  }

  close() {
    this.update({ open: false });
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }
}

// Exports an array of all the current instances
export const tableModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("pageLoad", (e) => {
    tableModules.current = [
      ...e.target.querySelectorAll(".js-table-module"),
    ].map((instance) => new TableModule(instance));
  });
};
